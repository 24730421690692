import ListGameItem from "../login/ListGameItem";
import {Grid, List, Typography} from "@mui/material";
import React from "react";
import useDefinedGames from "../../hooks/useDefinedGames";

export default function GameList() {
    const g_list = useDefinedGames()
    if (!g_list || g_list.length === 0) {
        return <Grid sx={{padding: 2}}>
            <Typography sx={{fontSize: 13}} color="text.secondary" gutterBottom>
                <i>No Games available</i>
                <br/>
                <i>Shared games will appear here</i>
            </Typography>
        </Grid>
    }
    return<Grid sx={{padding: 2}}>
            {
                g_list.map((s, k) => {
                    return <List component="nav" key={k}>
                        <ListGameItem name={s.gameName}/>
                    </List>
                })
            }
        </Grid>


}