import {useAppDispatch, useAppSelector} from "../redux/hooks";
import {gql, useQuery} from "@apollo/client";
import {dashboardSlice} from "../redux/store";

export const GET_COMMUNITIES_GQL = gql`query getCommunities($ath: String!){
  getCommunities(authToken: $ath){
    communityId
    communityName
    botName
    communityType
    connectionKey
    defaultUserStatus
    gainRate
    profileDuration
    taskDelayDays
    challengeMessage
    tagGameRoles
    tagExposure
    tagModerator
    tagAdmin
    chanNotification
    chanModerator
    chanJail
    metaData
    isPremiumCommunity
    interactionPointsDelay
    gameName
    installedGameName
    isPremiumGame
    shareStatus
    sharedGameDescription
  }
}`

export interface Community {
    communityId: number,
    communityName: string,
    botName: string,
    communityType: number,
    connectionKey: string,
    defaultUserStatus: number,
    gainRate: number,
    profileDuration: number,
    taskDelayDays: number,
    challengeMessage: string,
    tagGameRoles: string,
    tagExposure: string,
    tagModerator: string,
    tagAdmin: string,
    chanNotification: string,
    chanModerator: string,
    chanJail: string,
    metaData: string,
    isPremiumCommunity: number,
    interactionPointsDelay: number,
    gameName: string,
    installedGameName: string,
    isPremiumGame: number,
    shareStatus: number,
    sharedGameDescription: string
}

type ReqVars = { ath: string }

interface CommunityReturn {
    getCommunities: Array<Community>
}

export default function useCommunities(): Array<Community> {
    const authToken = useAppSelector(state => state.auth.authKey)
    const selectedServer = useAppSelector(s => s.dashboard.curServer)
    const dispatch = useAppDispatch()
    const {
        loading,
        error,
        data
    } = useQuery<CommunityReturn, ReqVars>(GET_COMMUNITIES_GQL, {variables: {ath: authToken}})
    if (loading) {
        return []
    }
    if (error) {
        console.log(error)
        return []
    } else if (data) {
        if (selectedServer){
            const updatedSelectedServer = data.getCommunities.find(s => s.communityId === selectedServer.communityId)
            if (updatedSelectedServer) {
                dispatch(dashboardSlice.actions.setCurServer(updatedSelectedServer))
            }
        }
        return data.getCommunities
    } else {
        return []
    }
}

