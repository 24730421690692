import useLogout, {LogoutStatus} from "../../hooks/useLogout";
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {authSlice, communitySlice, levelSlice} from "../../redux/store";
import {Button, Grid} from "@mui/material";
import React from "react";

export default function LogOutButton() {
    const logout = useLogout()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const {isLoggedIn, saveTaskState, saveLevelState, saveTaskGroupState, saveCommunityState} = useAppSelector(s => ({
        isLoggedIn: s.auth.isLoggedIn,
        saveTaskState: s.levels.unsavedTask,
        saveLevelState: s.levels.unsavedLevel,
        saveTaskGroupState: s.levels.unsavedTaskGroup,
        saveCommunityState: s.community.unsavedCommunity
    }))

    const onSubmit_Logout = () => {
        if(saveTaskState) {
            dispatch(levelSlice.actions.setSaveTaskStatus(true))
        }
        else if(saveTaskGroupState) {
            dispatch(levelSlice.actions.setSaveTaskGroupStatus(true))
        }
        else if(saveLevelState) {
            dispatch(levelSlice.actions.setSaveLevelStatus(true))
        }
        else if(saveCommunityState) {
            dispatch(communitySlice.actions.setSaveCommunityStatus(true))
        }
        else {
            if (isLoggedIn) {
                logout().then(v => {
                    switch (v) {
                        case LogoutStatus.NETWORK: {
                            break
                        }
                        case LogoutStatus.UNKNOWN: {
                            break
                        }
                        case LogoutStatus.ALREADY_LOGGED_OUT: {
                            navigate("/")
                            break
                        }
                        case LogoutStatus.OK: {
                            dispatch(authSlice.actions.setLoggedIn(false))
                            navigate("/")
                            window.location.reload()
                        }
                    }
                })
            } else {
                navigate("/")
            }
        }

    }
    return <Grid>
        <Button size={"small"} color={"success"} variant={"text"} onClick={onSubmit_Logout}>LOG
            OUT</Button>
    </Grid>
}