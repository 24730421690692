import {Button, Grid, Typography} from "@mui/material";
import React from "react";

export default function CannedGameItem({name, icon}: {name: string, icon: React.ReactNode}) {
    return  <Grid sm={2}>
        <Button variant={"outlined"} disabled={true} size={"large"}>
            {icon}
            <Typography variant={"body1"}>{name}</Typography>
        </Button>
    </Grid>
}

