import {UserCommunity} from "../../hooks/useUserCommunityList";
import {Button, Grid} from "@mui/material";

const DISCORD_BOT_ADD_URI = "https://discord.com/api/oauth2/authorize?" +
    "client_id=" + process.env.REACT_APP_DISCORD_CLIENT_ID +
    "&scope=bot&permissions=" + process.env.REACT_APP_DISCORD_PERMS +
    "&redirect_uri=" + encodeURIComponent(window.location.origin + process.env.REACT_APP_BOT_SUCCESS_PATH) +
    "&response_type=code" +
    "&disable_guild_select=true&guild_id="

export default function UserCommunityCard({community}: { community: UserCommunity }) {
    const uri = DISCORD_BOT_ADD_URI + community.id
    return <>
        <Grid container direction={"column"} justifyContent={"center"} alignItems={"center"}>
            <Button variant={"contained"} component={"a"} href={uri}>{community.name}</Button>
        </Grid>
    </>
}