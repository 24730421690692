import {useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useEffect} from "react";
import {useAppSelector} from "../redux/hooks";
import {gql, useMutation} from "@apollo/client";

const ADD_TOKEN_GQL = gql`mutation ($ath: String!, $code: String!, $cburi: String!)
{addAuthCode(authCode: $code, authToken: $ath, ouathCallBackURI: $cburi)}`

type addTokenInput = { ath: string, code: string, cburi: string }

export default function useAddToken() {
    const authToken = useAppSelector(s => s.auth.authKey)
    const {communityType} = useParams()
    const location = useLocation()
    const [searchParams, _] = useSearchParams()
    const navigate = useNavigate()
    const [addToken] = useMutation<{ addAuthCode: boolean }, addTokenInput>(ADD_TOKEN_GQL)
    useEffect(() => {
        const authCode = searchParams.get("code")
        if (authCode) {
            addToken({
                variables: {
                    ath: authToken, cburi: window.location.origin + location.pathname, code: authCode
                }
            }).then(v => {
                if (v.errors){
                    alert(v.errors[0])
                } else if (v.data) {
                    if (v.data.addAuthCode) {
                        navigate("/dashboard")
                    } else {
                        alert("Unable to add oauth")
                    }
                }
            }).catch(e => {
                alert(e)
                console.log(e)
            })
        }
    }, [communityType, authToken])
}