import * as React from 'react';
import {Dispatch, SetStateAction} from 'react';
import Box from '@mui/material/Box';
import useRoles from "../../../hooks/useRoles";
import {Chip, Grid} from "@mui/material";
import {User} from "../../../hooks/useGetUserById";

interface MultiSelectProps {
    selectedRoles: string,
    name: string,
    setState: Dispatch<SetStateAction<User>>
}

export default function ViewRoles(props: MultiSelectProps) {
    const names = useRoles()
    const curSelected = props.selectedRoles.split(', ')

    return <Box>
        {names.map((v, k) => {
            if (curSelected.includes(v.id)) {
                return <Grid key={k}>
                    <Chip label={v.name}/>
                </Grid>
            }
        })}
    </Box>
}
