import TopPanel from "../components/dashboard/TopPanel";
import {Box, Grid, Paper, Toolbar} from "@mui/material";
import React from "react";
import TabsPanel from "../components/dashboard/TabsPanel"
import LinkDialog from "../components/dashboard/LinkDialog";

export default function Dashboard() {
        return <Paper variant={"outlined"} square>
            <Box p={2} sx={{minHeight: "100vh"}}>
                <LinkDialog/>
                <Grid container direction={"row"} gap={5} alignItems={"space-evenly"}
                      justifyContent={"left"} pt={"1.5%"}>
                    <Grid container direction={"column"} gap={1} alignItems={"space-evenly"} maxHeight={"15vh"}>
                        <Grid sx={{zIndex: 2}}><TopPanel/></Grid>
                    </Grid>
                    <Toolbar/>
                    <Grid width={"100%"} minHeight={"85vh"} sx={{zIndex: 1}}>
                        <TabsPanel/>
                    </Grid>
                </Grid>
            </Box>
        </Paper>;
}