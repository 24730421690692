import {gql, useQuery} from "@apollo/client";
import {useAppSelector} from "../redux/hooks";

const ROLES_GQL = gql`query  getCommunityRoles($ath: String!, $communityId: Int!){
    getCommunityRoles(authToken: $ath, communityId: $communityId)
    }`

export interface CommunityRole {
    id: string,
    name: string
}

type ReqVars = { ath: string, communityId: number }


interface CommunityRolesReturn {
    getCommunityRoles: string
}

export default function useRoles(): Array<CommunityRole> {
    const communityId = useAppSelector(s => s.dashboard.curServer?.communityId as number)
    const authToken = useAppSelector(state => state.auth.authKey)
    const {loading, error, data} = useQuery<CommunityRolesReturn, ReqVars>(ROLES_GQL, {
        variables: {
            ath: authToken,
            communityId: communityId
        }
    })
    if (loading) {
        return []
    }
    if (error) {
        console.log(error)
        return []
    } else if (data) {
        return parseRoleNames(data.getCommunityRoles)
    } else {
        return []
    }
}


export function parseRoleNames(namesString: string) {
    let roleList = namesString.split(", ")
    if (roleList.at(roleList.length -1) == ""){
        roleList.pop()
    }
    roleList = roleList.filter(s => (s.includes(":"))) // weed out invalid stuff
    return roleList.map(r => {
        const arr = r.split(":")
        return {
            id: arr[0], name: arr[1].toLocaleLowerCase()
        }
    })
}