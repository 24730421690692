import {AppBar, Box, Button, Divider, Grid, Typography} from "@mui/material";
import React from "react";
import LevelyLogo from "./levelyLogo";
import LogOutButton from "./LogOutButton";
import AddToCommunity from "./AddToCommunity";

export const DISCORD_OAUTH_URI = `https://discord.com/api/oauth2/authorize?client_id=${process.env.REACT_APP_DISCORD_CLIENT_ID}&redirect_uri=${window.location.origin}${process.env.REACT_APP_OAUTH_CALLBACK_PATH}&response_type=code&scope=guilds%20identify`

export default function TopPanel() {
    return <Grid item xs={"auto"}>
        <Box sx={{flexShrink: 1, display: {xs: 'flex', md: 'flex'}}}>
            <Grid container direction={"column"} gap={3} alignItems={"center"} justifyContent={"space-around"}>
                <AppBar sx={{
                    p: 2,
                    minHeight: "9vh",
                    flexShrink: 1,
                    backgroundColor: "#090909",
                    opacity: "100%",
                    transparency: "100%",
                    zIndex: 2
                }}>
                    <Grid container direction={"row"} alignItems={"center"} justifyContent={"space-between"} >
                        <Grid sx={{zIndex: 3}}>
                            <LevelyLogo/>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container direction={"row"} gap={2} justifyContent={"flex-end"} >
                                    <Grid>
                                        <Typography variant={"body1"} alignItems={"center"}
                                                    justifyContent={"space-evenly"}>
                                            Sign in with</Typography>
                                    </Grid>
                                <Grid container direction={"row"} gap={2} justifyContent={"flex-end"}>
                                    <Grid>
                                        <Button size={"small"} color={"info"} variant={"outlined"} component={"a"}
                                                href={DISCORD_OAUTH_URI}>Discord</Button>
                                    </Grid>
                                    <Grid>
                                        <Button size={"small"} color={"info"} variant={"outlined"} component={"a"}
                                                disabled={true}>Slack</Button>
                                    </Grid>
                                    <Grid>
                                        <Button size={"small"} color={"info"} variant={"outlined"} component={"a"}
                                                disabled={true}>Your
                                            Website</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Divider orientation={"vertical"} flexItem/>
                        <Grid item xs={2}>
                            <Grid container direction={"column"} gap={2} justifyContent={"flex-end"}>
                                <Grid>
                                    <AddToCommunity/>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={1}>
                            <Grid>
                                <LogOutButton/>
                            </Grid>
                        </Grid>
                    </Grid>
                </AppBar>
            </Grid>
        </Box>
    </Grid>

}