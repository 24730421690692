import {Grid, TableCell, TableRow, Typography} from "@mui/material";
import React from "react";
import useGetUserTaskHistory from "../../../hooks/useGetUserTaskHistory";

export default function TaskHistory({uid}: {uid: number}) {
    const th_list = useGetUserTaskHistory(uid)
    if(!th_list || th_list.length == 0) {
        return <Grid sx={{padding: 2}}>
            <Typography sx={{fontSize: 13}} color="text.secondary" gutterBottom>
                <i>No Tasks available</i>
            </Typography>
        </Grid>
    }
    return <>
        {
            th_list.map((s, k) => {
                return <>
                <TableRow key={k}>
                    <TableCell>
                        {s.historyId}
                    </TableCell>
                    <TableCell>
                        {s.TaskId}
                    </TableCell>
                    <TableCell>
                        {s.dateIssued !== "0001-01-01 00:00:00" ? s.dateIssued : "-"}
                    </TableCell>
                    <TableCell>
                        {s.dateAchieved !== "0001-01-01 00:00:00" ? s.dateAchieved : "-"}
                    </TableCell>
                    <TableCell>
                        {s.rejectedCount}
                    </TableCell>
                </TableRow>
                </>
            })
        }
    </>
}