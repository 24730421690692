import {configureStore, createSlice, PayloadAction} from "@reduxjs/toolkit";
import Cookies from "universal-cookie";
import {Community} from "../hooks/useCommunity";
import {CommunityLevel, CommunityTask, CommunityTaskGroup} from "../hooks/useGetLevels";
import {User} from "../hooks/useGetUserByNickname";

interface AuthState {
    isLoggedOut: boolean;
    hasCreatedAccount: boolean;
    authKey: string,
    isLoggedIn: boolean,
}

interface DashboardState {
    curServer: Community | null
}

interface MemberState {
    curNickname: User | null
}

interface CommunityState {
    unsavedCommunity: boolean,
    unsavedCommunityStatus: boolean
}

interface LevelState {
    currentLevel: CommunityLevel | null,
    currentTaskGroup: CommunityTaskGroup | null,
    currentTask: CommunityTask | null,
    openEditTaskDrawer: boolean,
    openEditLevelDrawer: boolean,
    openEditTaskGroupDrawer: boolean,
    unsavedLevel: boolean,
    unsavedTaskGroup: boolean,
    unsavedTask: boolean,
    unsavedLevelStatus: boolean,
    unsavedTaskGroupStatus: boolean,
    unsavedTaskStatus: boolean

}

const cookies = new Cookies();
const ath = cookies.get('authToken')

export const authSlice = createSlice({
    name: "auth", initialState: {authKey: ath as string, isLoggedIn: false} as AuthState, reducers: {
        setAuthKey(state: AuthState, action: PayloadAction<string>) {
            state.authKey = action.payload
        }, setLoggedIn(state: AuthState, action: PayloadAction<boolean>) {
            state.isLoggedIn = action.payload
        }, setCreatedAccount(state: AuthState, action: PayloadAction<boolean>) {
            state.hasCreatedAccount = action.payload
        }

    }
})

export const dashboardSlice = createSlice({
    name: "dashboard", initialState: {curServer: null} as DashboardState, reducers: {
        setCurServer(state, action: PayloadAction<Community | null>) {
            state.curServer = action.payload
        }
    }
})

export const memberSlice = createSlice({
    name: "member", initialState: {curNickname: null} as MemberState, reducers: {
        setCurNickname(state, action: PayloadAction<User | null>) {
            state.curNickname = action.payload
        }
    }
})

export const communitySlice = createSlice({
    name: "community",
    initialState: {
        unsavedCommunity: false,
        unsavedCommunityStatus: false
    } as CommunityState,
    reducers: {
        setSaveCommunity(state, action: PayloadAction<boolean>) {
            state.unsavedCommunity = action.payload
        }, setSaveCommunityStatus(state, action: PayloadAction<boolean>) {
            state.unsavedCommunityStatus = action.payload
        }
    }
})

export const levelSlice = createSlice({
    name: "levels",
    initialState: {
        currentLevel: null,
        currentTask: null,
        currentTaskGroup: null,
        openEditTaskDrawer: false,
        openEditLevelDrawer: false,
        openEditTaskGroupDrawer: false,
        unsavedLevel: false,
        unsavedTaskGroup: false,
        unsavedTask: false,
        unsavedLevelStatus: false,
        unsavedTaskGroupStatus: false,
        unsavedTaskStatus: false
    } as LevelState,
    reducers: {
        setCurrentLevel(state, action: PayloadAction<CommunityLevel | null>) {
            state.currentLevel = action.payload
        }, setCurrentTaskGroup(state, action: PayloadAction<CommunityTaskGroup | null>) {
            state.currentTaskGroup = action.payload
        }, setCurrentTask(state, action: PayloadAction<CommunityTask | null>) {
            state.currentTask = action.payload
        }, setOpenEditTaskDrawer(state, action: PayloadAction<boolean>) {
            state.openEditTaskDrawer = action.payload
        }, setOpenEditLevelDrawer(state, action: PayloadAction<boolean>) {
            state.openEditLevelDrawer = action.payload
        }, setOpenEditTaskGroupDrawer(state, action: PayloadAction<boolean>) {
            state.openEditTaskGroupDrawer = action.payload
        }, setSaveLevel(state, action: PayloadAction<boolean>) {
            state.unsavedLevel = action.payload
        }, setSaveTaskGroup(state, action: PayloadAction<boolean>) {
            state.unsavedTaskGroup = action.payload
        }, setSaveTask(state, action: PayloadAction<boolean>) {
            state.unsavedTask = action.payload
        }, setSaveLevelStatus(state, action: PayloadAction<boolean>) {
            state.unsavedLevelStatus = action.payload
        }, setSaveTaskGroupStatus(state, action: PayloadAction<boolean>) {
            state.unsavedTaskGroupStatus = action.payload
        }, setSaveTaskStatus(state, action: PayloadAction<boolean>) {
            state.unsavedTaskStatus = action.payload
        }
    }
})

export const store = configureStore({
    reducer: {
        auth: authSlice.reducer,
        dashboard: dashboardSlice.reducer,
        member: memberSlice.reducer,
        levels: levelSlice.reducer,
        community: communitySlice.reducer
    }
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
