import {gql, useMutation} from "@apollo/client";
import {useAppSelector} from "../redux/hooks";
import {GET_LEVELS_GQL} from "./useGetLevels";

const DELETE_TASK_MUTATION = gql`mutation deleteTask($auth: String!, $taskId: Int!) {
    deleteTask(authToken: $auth, taskId: $taskId)
    }`

interface DeleteTaskVariables {
    auth: string,
    taskId: number
}

interface DeleteTaskReturn {
    deleteTask: number
}

export enum DeleteTaskStatus {
    OK,
    NO_RIGHT,
    UNKNOWN
}

export default function useDeleteTask() {
    const [deleteTask] = useMutation<DeleteTaskReturn, DeleteTaskVariables>(DELETE_TASK_MUTATION, {
        refetchQueries: [GET_LEVELS_GQL]
    })
    const auth = useAppSelector(s => s.auth.authKey)
    const taskId = useAppSelector(s => s.levels.currentTask?.taskId as number)

    return () => {
        return deleteTask({variables: { taskId: taskId, auth: auth}}).then(r => {
            if(r.errors) {
                const e = r.errors[0]
                const emesg = e.toString()
                if (emesg.includes("INVCOM")) {
                    return DeleteTaskStatus.NO_RIGHT
                }
                return DeleteTaskStatus.UNKNOWN
            }
            if(r.data) {
                if(r.data.deleteTask !== null) {
                    return DeleteTaskStatus.OK
                } else {
                    return DeleteTaskStatus.UNKNOWN
                }
            } else {
                return DeleteTaskStatus.UNKNOWN
            }
        }).catch(e => {
            const emesg = e.toString()
            if (emesg.includes("INVCOM")) {
                return DeleteTaskStatus.NO_RIGHT
            }
            return DeleteTaskStatus.UNKNOWN
        })
    }
}