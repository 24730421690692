import {useAppSelector} from "../../../redux/hooks";
import useGetLeaderBoard from "../../../hooks/useGetLeaderBoard";
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";
import React, {Dispatch} from "react";

export default function LeaderBoard({selectedRowId, setSelectedRowId}
                                        : { selectedRowId: number, setSelectedRowId: Dispatch<number> }) {

    const curState = useAppSelector(s => s.dashboard.curServer)
    const lb_list = useGetLeaderBoard(curState?.communityId as number)

    if (!lb_list || lb_list.length === 0) {
        return <>
            <Typography sx={{fontSize: 13}} color="text.secondary" gutterBottom>
                <i>No Members available</i>
                <br/>
                <i>Members in the game will appear here</i>
            </Typography>
        </>
    }
    return <>
        <TableContainer component={Paper} sx={{maxHeight: "80vh", overflowX: "hidden"}}>
            <Table stickyHeader={true} size={"small"}>
                <TableHead>
                    <TableRow>
                        <TableCell><b>XP</b></TableCell>
                        <TableCell><b>Nickname</b></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>

                    {lb_list.map((s, k) => {
                        return <>
                            <TableRow key={k} selected={s.userId == selectedRowId} onClick={() => setSelectedRowId(s.userId)}>
                                <TableCell>{s.experiencePoints}</TableCell>
                                <TableCell>{s.userNickname}</TableCell>
                            </TableRow>
                        </>

                    })}
                </TableBody>
            </Table>
        </TableContainer>
    </>
}