import {CommunityTask} from "../../../hooks/useGetLevels";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {Card, List, Typography} from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import {levelSlice} from "../../../redux/store";

export default function SelectTask(props: { tasks: CommunityTask[] }) {
    const {taskGroup, currentLevel, currentTask} = useAppSelector(s => ({
        taskGroup: s.levels.currentTaskGroup, currentLevel: s.levels.currentLevel, currentTask: s.levels.currentTask
    }))
    const dispatch = useAppDispatch()
    const saveTaskState = useAppSelector(s => s.levels.unsavedTask)
    const saveLevelState = useAppSelector(s => s.levels.unsavedLevel)
    const saveTaskGroupState = useAppSelector(s => s.levels.unsavedTaskGroup)

    if (taskGroup === null || currentLevel == null) {
        return <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
            <i>Select Level and Task Group combination to show tasks</i>
        </Typography>
    }

    const levelsToShow = (props.tasks !== null ? props.tasks.filter(l => {
        return l.taskLevelId === currentLevel.levelId && l.taskGroupId === taskGroup.groupId
    }) : [])

    if (levelsToShow.length == 0) {
        return <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
            <i>No Tasks available</i>
        </Typography>
    }

    const ocf = (task: CommunityTask) => {
        if(saveTaskState) {
            dispatch(levelSlice.actions.setSaveTaskStatus(true))
        }
        else if(saveTaskGroupState) {
            dispatch(levelSlice.actions.setSaveTaskGroup(true))
        }
        else if(saveLevelState) {
            dispatch(levelSlice.actions.setSaveLevel(true))
        }
        else {
            dispatch(levelSlice.actions.setCurrentTask(task))
            dispatch(levelSlice.actions.setOpenEditTaskGroupDrawer(false))
            dispatch(levelSlice.actions.setOpenEditLevelDrawer(false))
            dispatch(levelSlice.actions.setOpenEditTaskDrawer(true))
        }
    }

    return <Card>
        <List>
            {levelsToShow.map((l, i) => {
                return <ListItemButton selected={((currentTask !== null) && (l.taskId === currentTask.taskId))}
                                       onClick={() => ocf(l)} key={i}>
                    <Typography maxWidth={"28rem"} overflow={"hidden"} textOverflow={"ellipsis"}
                                whiteSpace={"nowrap"}>{l.taskDescription}</Typography>
                </ListItemButton>
            })}
        </List>
    </Card>
}