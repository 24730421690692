import {useAppDispatch, useAppSelector} from "../redux/hooks";
import {authSlice} from "../redux/store";
import Cookies from "universal-cookie";
import {useEffect, useState} from "react";
import {gql, useLazyQuery} from "@apollo/client";
import {useNavigate} from "react-router-dom";

const GET_AUTH_QUERY = gql`{getAuthToken}`
const VERIFY_AUTH_QUERY = gql`query ($auth: String!){
  verifyLogin(authToken: $auth){
    userId,
  }
}`

const setAuthTokenCookie = (authToken: string) => {
    (new Cookies()).set("authToken", authToken, {path: "/", maxAge: 604800})
}

export default function useGetOrValidateAuthKey() {
    const SAuthToken = useAppSelector(state => state.auth.authKey)
    const dispatch = useAppDispatch()
    const [rv, setRv] = useState("")
    const cookies = new Cookies()
    const oldAth = cookies.get("authToken")
    let authToken = SAuthToken
    if (oldAth !== undefined) {
        authToken = oldAth
    }

    const [getAuthKey, {loading, error}] = useLazyQuery<{ getAuthToken: string }>(GET_AUTH_QUERY)
    const [verifyLogin] = useLazyQuery<null | { verifyLogin: { userId: number} }, { auth: string }>(VERIFY_AUTH_QUERY)
    const navigate = useNavigate()
    useEffect(() => {
        if (authToken) {
            verifyLogin({variables: {auth: authToken}}).then(v => {
                if (v.loading) {
                    return null
                } else if (v.error) {
                    throw v.error
                } else {
                    if (v.data?.verifyLogin === null) {
                        dispatch(authSlice.actions.setLoggedIn(false))
                    } else {
                        dispatch(authSlice.actions.setLoggedIn(true))
                        navigate('/dashboard')
                    }
                    dispatch(authSlice.actions.setAuthKey(authToken))
                    setRv(authToken)
                }
            }).catch(e => {
                console.log(e)
                const err = e as Error
                if (err.message.includes("IAUTHTK")) {
                    cookies.remove("authToken")
                    window.location.reload()
                }
            })

        } else {
            getAuthKey().then(value => {
                if (loading) {
                    return
                } else if (error) {
                    console.log(error)
                } else {
                    const authToken = value.data?.getAuthToken
                    console.log(authToken)
                    if (authToken) {
                        setAuthTokenCookie(authToken)
                        dispatch(authSlice.actions.setAuthKey(authToken))
                        setRv(authToken)
                    }
                }
            }).catch(e => {
                console.log(e)
            })
        }
    }, [])

    return rv
}