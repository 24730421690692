import {gql, useMutation} from "@apollo/client";
import {useAppSelector} from "../redux/hooks";
import {GET_LEVELS_GQL} from "./useGetLevels";

const SAVE_LEVEL_GQL = gql`mutation createUpdateLevel($ath: String!, $level: LevelIn!) {
    createUpdateLevel(authToken: $ath, levelData: $level)
    }`

export interface LevelIn {
    levelId?: number,
    communityId: number,
    levelName: string,
    levelAdjective?: string,
    xpThreshold: number,
    achievementURL?: string,
    achievementTags?: string
}

interface SaveLevelInput {
    ath: string,
    level: LevelIn
}

export enum SaveLevelStatus {
    NO_CHANGE,
    NO_RIGHT,
    IAUTHTK,
    ALREADY_LOGGED_OUT,
    NETWORK,
    INVALID_LEVEL,
    INVALID_THRESHOLD,
    DATABASE,
    UNKNOWN,
    OK
}

interface SaveLevelOutput {
    createUpdateLevel: number
}

export default function useSaveLevel() {
    const [saveLevel] = useMutation<SaveLevelOutput, SaveLevelInput>(SAVE_LEVEL_GQL, {
        refetchQueries: [GET_LEVELS_GQL]
    })

    const {curLevelId, ath, cid} = useAppSelector(s => ({
        curLevelId: s.levels.currentLevel?.levelId as number,
        ath: s.auth.authKey,
        cid: s.dashboard.curServer?.communityId as number
    }))

    return (s: LevelIn) => {
        const data: SaveLevelInput = {
            level: {
                levelId: curLevelId,
                communityId: cid,
                levelName: s.levelName,
                levelAdjective: s.levelAdjective,
                xpThreshold: s.xpThreshold,
                achievementTags: s.achievementTags,
                achievementURL: s.achievementURL
            }, ath: ath
        }
        return saveLevel({variables: data}).then(r => {
            if (r.errors) {
                const e = r.errors[0]
                const emesg = e.toString()
                if (emesg.includes("INVCOM")) {
                    return SaveLevelStatus.NO_RIGHT
                } else if (emesg.includes("IAUTHTK")) {
                    window.location.reload()
                } else if (emesg.includes("NOUSER")) {
                    return SaveLevelStatus.ALREADY_LOGGED_OUT
                } else if (emesg.includes("CUDBFAIL")) {
                    return SaveLevelStatus.NO_CHANGE
                } else if (emesg.includes("INVLEVEL")) {
                    return SaveLevelStatus.INVALID_LEVEL
                } else if (emesg.includes("INVTHRESH")) {
                    return SaveLevelStatus.INVALID_THRESHOLD
                } else if (emesg.includes("DBINT")) {
                    return SaveLevelStatus.DATABASE
                }
                return SaveLevelStatus.UNKNOWN
            }
            const data = r.data
            if (data) {
                if (data.createUpdateLevel !== null) {
                    return SaveLevelStatus.OK
                } else {
                    return SaveLevelStatus.UNKNOWN
                }
            } else {
                return SaveLevelStatus.UNKNOWN
            }
        }).catch(e => {
            const emesg = e.toString()
            if (emesg.includes("Failed to fetch")) {
                return SaveLevelStatus.NETWORK
            } else if (emesg.includes("INVCOM")) {
                return SaveLevelStatus.NO_RIGHT
            } else if (emesg.includes("IAUTHTK")) {
                window.location.reload()
                return SaveLevelStatus.UNKNOWN
            } else if (emesg.includes("NOUSER")) {
                return SaveLevelStatus.ALREADY_LOGGED_OUT
            } else if (emesg.includes("CUDBFAIL")) {
                return SaveLevelStatus.NO_CHANGE
            } else if (emesg.includes("INVLEVEL")) {
                return SaveLevelStatus.INVALID_LEVEL
            } else if (emesg.includes("INVTHRESH")) {
                return SaveLevelStatus.INVALID_THRESHOLD
            } else if (emesg.includes("DBINT")) {
                return SaveLevelStatus.DATABASE
            } else {
                return SaveLevelStatus.UNKNOWN
            }
        })
    }
}