import {useAppSelector} from "../redux/hooks";
import {gql, useQuery} from "@apollo/client";
import {useNavigate} from "react-router-dom";
import {cleanCookies} from "universal-cookie/es6/utils";

const GET_USER_LINK_GQL = gql`query ($ath: String!){
  getUserLinks(authToken:$ath)
}`

export default function useUserLinks() {
    const navigate = useNavigate()
    const authToken = useAppSelector(s => s.auth.authKey)
    const {
        loading,
        error,
        data
    } = useQuery<{ getUserLinks: Array<number> }, { ath: string }>(GET_USER_LINK_GQL, {variables: {ath: authToken}})
    if (loading) {
        return []
    } else if (error) {
        if (error.message.includes("IAUTHTK")){
            cleanCookies()
            navigate("/")
        }
        console.log(error)
        return []
    } else {
        if (data?.getUserLinks !== null){
            return data?.getUserLinks
        } else {
            return []
        }
    }
}