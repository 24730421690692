import {Box, Button, Card, Chip, Divider, Grid, Paper, TextField, Typography} from "@mui/material";
import {Boy, Diamond, FitnessCenter, Javascript, OutdoorGrill} from "@mui/icons-material";
import React, {useState} from "react";
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import useLogin, {LoginStatus} from "../hooks/useLogin";
import {authSlice} from "../redux/store";
import {useNavigate} from "react-router-dom";
import useCreateAccount, {CreateAccountStatus} from "../hooks/useCreateAccount";
import CannedGameItem from "../components/login/CannedGameItem";
import LevelyLogo from "../components/dashboard/levelyLogo";
import GameList from "../components/login/GameList";
import Dropdown from "../components/login/Dropdown";


type formVal_login = { uname_login: string, passwd_login: string }
type formVal_createaccount = { uname_createaccount: string, passwd_createaccount: string, passwd_createaccount_reenter: string }

export default function Login() {
    const [state_1, setState_1] = useState<formVal_login>({uname_login: "", passwd_login: ""})
    const [state_2, setState_2] = useState<formVal_createaccount>({
        uname_createaccount: "",
        passwd_createaccount: "",
        passwd_createaccount_reenter: ""
    })
    const [emesg_1, setEmesg_1] = useState("")
    const [emesg_2, setEmesg_2] = useState("")

    const dispatch = useAppDispatch()
    const login = useLogin()
    const createAccount = useCreateAccount()
    const navigate = useNavigate()

    const {isLoggedIn} = useAppSelector(s => {
        return {isLoggedIn: s.auth.isLoggedIn}
    })
    const {hasCreatedAccount} = useAppSelector(s => {
        return {hasCreatedAccount: s.auth.hasCreatedAccount}
    })

    const onChange_1 = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState_1({...state_1, [e.target.name]: e.target.value})

    }
    const onChange_2 = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState_2({...state_2, [e.target.name]: e.target.value})
    }

    const onSubmit_Login = async (e: React.FormEvent) => {
        e.preventDefault()
        if (!isLoggedIn) {
            login(state_1.uname_login, state_1.passwd_login).then(v => {
                switch (v) {
                    case LoginStatus.NETWORK: {
                        setEmesg_1("Network error")
                        break
                    }
                    case LoginStatus.UNKNOWN: {
                        setEmesg_1("Unknown error")
                        break
                    }
                    case LoginStatus.INVALID_PASS: {
                        setEmesg_1("Invalid Password")
                        break
                    }
                    case LoginStatus.INVALID_USERNAME: {
                        setEmesg_1("Invalid Username")
                        break
                    }
                    case LoginStatus.UNKNOWN_USERNAME: {
                        setEmesg_1("Unknown Username")
                        break
                    }
                    case LoginStatus.OK: {
                        dispatch(authSlice.actions.setLoggedIn(true))
                        navigate("/dashboard")
                    }
                }
            })
        }
    }

    const onSubmit_CreateAccount = async (e: React.FormEvent) => {
        e.preventDefault()
        const {passwd_createaccount, passwd_createaccount_reenter} = state_2;
        if (passwd_createaccount !== passwd_createaccount_reenter)
            setEmesg_2("Passwords do not match")
        else {
            if (!hasCreatedAccount) {
                createAccount(state_2.uname_createaccount, state_2.passwd_createaccount).then(v => {
                    switch (v) {
                        case CreateAccountStatus.OK: {
                            navigate("/");
                            setEmesg_2("Account Created!")
                            window.location.reload();
                            break
                        }
                        case CreateAccountStatus.USER_LOGGED_IN: {
                            setEmesg_2("User already logged in")
                            break
                    }
                    case CreateAccountStatus.DB_ERROR: {
                        setEmesg_2("Database error.")
                        break
                    }
                    case CreateAccountStatus.POOR_PASSWD: {
                        setEmesg_2("Password length must be at least 8 characters")
                        break
                    }
                    case CreateAccountStatus.POOR_USERNAME: {
                        setEmesg_2("Username must be between 3 and 25 characters")
                        break
                    }
                    case CreateAccountStatus.USER_EXISTS : {
                        setEmesg_2("A user with the same name exists already")
                        break
                    }
                        case CreateAccountStatus.UNKNOWN: {
                            setEmesg_2("An unknown error occurred")
                            break
                        }
                        default: {
                            setEmesg_2("An unknown error occurred")
                            break
                        }
                    }
                })
            }
        }
    }


    return <Paper variant={"outlined"} square>
        <Box p={2} sx={{minHeight: "100vh"}}>
            <Grid container direction={"row"} spacing={1} gap={2} alignItems={"space-between"}
                  justifyContent={"center"} sx={{paddingTop: "1.5%"}}>
                <Grid item xs={12} md={3}>


                    <Grid container direction={"column"} spacing={1} gap={2} alignItems={"center"}
                          justifyContent={"center"}>

                        <LevelyLogo/>
                        <Grid>
                            <Typography textAlign={"left"} variant={"h6"}>Use Existing Levely Login</Typography>
                        </Grid>

                       <form onSubmit={onSubmit_Login}>
                           <Grid container direction={"column"} spacing={1} gap={1} alignItems={"center"}
                                 justifyContent={"center"}>
                               <TextField size={"small"} name={"uname_login"} value={state_1.uname_login}
                                          onChange={onChange_1} label={"Username"}/>
                               <TextField size={"small"} name={"passwd_login"} value={state_1.passwd_login}
                                          onChange={onChange_1} label={"Password"}
                                          inputProps={{type: "password"}}/>
                               <Button variant={"contained"} type={"submit"}>Log In</Button>
                           </Grid>
                       </form>

                        <Grid item hidden={!emesg_1}>
                            <Typography variant={"subtitle2"} color={"error"}>{emesg_1}</Typography>
                        </Grid>
                        <Divider orientation="horizontal" flexItem>
                            <Chip label="OR"/>
                        </Divider>
                        <Grid>
                            <Typography textAlign={"left"} variant={"h6"}>Create New Levely Account (free)</Typography>
                        </Grid>

                        <form onSubmit={onSubmit_CreateAccount}>
                            <Grid container direction={"column"} spacing={0} gap={1} alignItems={"center"}
                                  justifyContent={"center"}>
                                <TextField size={"small"} name={"uname_createaccount"} value={state_2.uname_createaccount}
                                           onChange={onChange_2} label={"Username"}/>
                                <TextField size={"small"} name={"passwd_createaccount"} value={state_2.passwd_createaccount}
                                           onChange={onChange_2} label={"Password"}
                                           inputProps={{type: "password"}}/>
                                <TextField size={"small"} name={"passwd_createaccount_reenter"}
                                           value={state_2.passwd_createaccount_reenter}
                                           onChange={onChange_2} label={"Re-enter Password"}
                                           inputProps={{type: "password"}}/>
                                <Button variant={"contained"} type={"submit"}>Create Account</Button>
                            </Grid>
                        </form>
                        <Grid item>
                            <Typography textAlign={"center"} variant={"body1"}>Password has to be at least 8
                                characters.</Typography>
                            <Typography textAlign={"center"} variant={"body1"}>Username has to be between 3 and 25
                                characters.</Typography>

                        </Grid>
                        <Grid item hidden={!emesg_2}>
                            <Typography variant={"subtitle2"} color={"error"}>{emesg_2}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Divider orientation="vertical" flexItem>

                    </Divider>
                </Grid>
                <Grid item xs={10} md={8}>
                    <Grid container direction={"column"} spacing={3} gap={3} alignItems={"left"}
                          justifyContent={"space-between"}>
                        <Grid item>
                            <Typography textAlign={"left"} variant={"h5"}>Install one of our prebuilt games or build
                                your own!
                            </Typography>
                        </Grid>
                        <Grid container direction={"row"} spacing={2} gap={2} alignItems={"center"}
                              justifyContent={"space-evenly"}>
                            <CannedGameItem name={"Boy Wizard"} icon={<Boy fontSize={"large"}/>}/>
                            <CannedGameItem name={"Javascript Expertise"} icon={<Javascript fontSize={"large"}/>}/>
                            <CannedGameItem name={"Lord of the Rings"} icon={<Diamond fontSize={"large"}/>}/>
                            <CannedGameItem name={"Stay Fit"} icon={<FitnessCenter fontSize={"large"}/>}/>
                            <CannedGameItem name={"Cooking Challenge"} icon={<OutdoorGrill fontSize={"large"}/>}/>
                        </Grid>
                        <Grid item>
                            <Typography textAlign={"left"} variant={"body2"}>A complete list of our prebuilt games is
                                below
                            </Typography>
                            <Typography textAlign={"left"} variant={"body2"}>Note that some are premium games and
                                require a
                                subscription to Levely
                            </Typography>
                        </Grid>
                        <Grid container direction={"row"} spacing={2} gap={2} alignItems={"space-between"}>
                            <Grid item xs={12} md={5}><Card sx={{minHeight: 300}}>
                                <GameList/>
                            </Card>
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <Typography textAlign={"left"} variant={"body1"}>What's involved:
                                </Typography>

                                <Typography textAlign={"justify"} variant={"body2"}>Whether you configure an existing
                                    game or you create your own, there are a few steps you will need to do in Discord to
                                    make all this work.
                                </Typography>
                                <br/>
                                <Dropdown name={"Select a game"}
                                          content={"1) Select a game you want to add to your server."}/>
                                <Dropdown name={"Add roles and challenges"}
                                          content={"2) Add some roles to your Discord server for your users to select into. These will vary by game and will tailor the challenges for them. For example, for the cooking challenge, they will need to selectwhether theyare interested in baking, frying, grilling, or soup making. The challenges foreach rolewill be different but they are balanced and allow players to compete head to head."}/>
                                <Dropdown name={"Add moderator roles"}
                                          content={"3) Create a role for your game moderators(no need to burden your admins). Most games require some amount of moderation."}/>
                                <Dropdown name={"Game play choices"}
                                          content={"4) Make a few choices about how you want your game play to work in your server."}/>
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    </Paper>;
}