import {Card, Grid, Typography} from "@mui/material";
import {useAppSelector} from "../../../redux/hooks";
import useGetLevels from "../../../hooks/useGetLevels";
import SelectLevel from "./SelectLevel";
import SelectTaskGroup from "./SelectTaskGroup";
import SelectTask from "./SelectTask";
import CreateEditTask from "./CreateEditTask";
import CreateTaskButton from "./CreateTaskButton";
import CreateEditLevel from "./CreateEditLevel";
import CreateLevelButton from "./CreateLevelButton";
import CreateEditGroup from "./CreateEditTaskGroup";
import CreateGroupButton from "./CreateTaskGroupButton";
import React from "react";

const Levels = () => {
    const curSelectedServer = useAppSelector(s => s.dashboard.curServer)
    const levels = useGetLevels(curSelectedServer?.communityId as number)
    const curTask = useAppSelector(s => s.levels.currentTask)
    const curLevel = useAppSelector(s => s.levels.currentLevel)
    const curGroup = useAppSelector((s=>s.levels.currentTaskGroup))
    if (curSelectedServer === null) {
        return <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            <i>Select Community to show details</i>
        </Typography>
    }
    if (levels === -1 || levels === null || levels === undefined) {
        return null
    }

    return <Grid gap={2} container direction={"row"} justifyContent={"space-around"} alignItems={"start"}>
        <Grid>
            <Grid gap={2} container direction={"column"} alignItems={"center"}>
                <Card sx={{padding: 2}}>
                    <Grid container direction={"row"} alignItems={"center"} width={530} gap={2}
                          justifyContent={"flex-end"}>
                        <Grid container gap={2} direction={"row"} justifyContent={"space-between"}>
                            <Card variant={"outlined"} sx={{padding: 2}}>
                                <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
                                    Level
                                </Typography>
                                <Grid container justifyContent={"space-between"} direction={"row"} gap={1} width={500}>
                                    <SelectLevel levels={levels.communityLevels}/>
                                    <CreateLevelButton/>
                                </Grid>
                            </Card>

                            <Card variant={"outlined"} sx={{padding: 2}}>
                                <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
                                    Task Group
                                </Typography>
                                <Grid container justifyContent={"space-between"} direction={"row"} gap={1} width={500}>
                                    <SelectTaskGroup taskGroups={levels.taskGroup}/>
                                    <CreateGroupButton/>
                                </Grid>
                            </Card>
                        </Grid>
                    </Grid>
                </Card>
                <Card sx={{padding: 2}}>
                    <Grid container direction={"column"} gap={1}>
                        <Grid container gap={1} direction={"row"} justifyContent={"space-between"}>
                            <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
                                Tasks
                            </Typography>
                            <Grid sx={{padding: 0}}>
                                <CreateTaskButton/>
                            </Grid>
                        </Grid>
                        <Grid width={530}>
                            <Grid container direction={"column"} alignItems={"flex-end"} padding={0} gap={1}
                                  width={530}>
                                <Card variant={"outlined"} sx={{padding: 2}}>
                                    <SelectTask tasks={levels.taskList}/>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
        </Grid>
        <Grid width={300}>
            <CreateEditTask key={curTask?.taskId}/>
            <CreateEditLevel key={curLevel?.levelId}/>
            <CreateEditGroup key={curGroup?.groupId}/>
        </Grid>
    </Grid>
}

export default Levels;