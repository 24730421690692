import {Button, Dialog, Grid, Typography} from "@mui/material";
import React from "react";
import {DISCORD_OAUTH_URI} from "./TopPanel";
import LogOutButton from "./LogOutButton";
import useUserLinks from "../../hooks/useUserLinks";

export default function LinkDialog() {
    const UserLinks = useUserLinks()
    return <Dialog open={UserLinks?.length === 0}>
        <Grid>
            <Grid container direction={"row"} gap={2} padding={2} justifyContent={"center"}>
                <Grid>
                    <Typography variant={"body1"} alignItems={"center"}
                                justifyContent={"space-evenly"}>
                        Sign in with</Typography>
                </Grid>
                <Grid container direction={"row"} gap={2} justifyContent={"center"}>
                    <Grid>
                        <Button size={"small"} color={"secondary"} variant={"outlined"}
                                href={DISCORD_OAUTH_URI}>Discord</Button>
                    </Grid>
                    <Grid>
                        <Button size={"small"} color={"secondary"} variant={"outlined"}
                                disabled={true}>Slack</Button>
                    </Grid>
                    <Grid>
                        <Button size={"small"} color={"secondary"} variant={"outlined"}
                                disabled={true}>Your
                            Website</Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        <Grid container justifyContent={"flex-end"} padding={1}>
        <LogOutButton/>
        </Grid>
        </Dialog>
}