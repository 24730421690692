import {Card, CardMedia, Grid} from '@mui/material';
import * as React from 'react';
import LogoBig from "../login/LevelyBIG01.png"

const LevelyLogo = () => {

    return (<Card sx={{maxWidth: 300}} elevation={0} >
        <Grid padding={2}>
            <CardMedia
                component="img"
                alt="levely-logo"
                height="50"
                image={LogoBig}
            /></Grid>
    </Card>)
        ;
};
export default LevelyLogo;
