import {gql, useLazyQuery} from "@apollo/client";
import {useAppSelector} from "../redux/hooks";
import {CommunityLevel, CommunityTask} from "./useGetLevels";

const GET_USER_GQL = gql`query getUserById($ath: String!, $communityId: Int!, $userId: Int!){
    getUserById(authToken: $ath, communityId: $communityId, userId: $userId){
        userId
        communityId
        createDate
        startDate
        endDate
        profileSyncDate
        endJailDate
        userStatus
        userNickname
        percentGainRate
        experiencePoints
        userPermission
        exposureAllowed
        tagsEarned
        levelAchieved {
          levelId
          communityId
          levelName
          levelAdjective
          xpThreshold
          achievementURL
          achievementTags
          }
        levelChallenged {
          levelId
          communityId
          levelName
          levelAdjective
          xpThreshold
          achievementURL
          achievementTags
          }
        outstandingTask {
          taskId
          communityId
          taskGroupId
          taskLevelId
          taskDescription
          taskImageURL
          taskDifficulty
          taskExposureFlag
          taskApprovalSteps
          taskType
          taskAnswer
          taskParameters
          }
        }
    }`

type ReqVars = { ath: string, communityId: number, userId: number }

//getUserById and getUserByNickname have different output formats but both are exported as "User"
export interface User {
    userId: number,
    communityId: number,
    createDate: string,
    startDate: string,
    endDate: string,
    profileSyncDate: string,
    endJailDate: string,
    userStatus: number,
    userNickname: string,
    percentGainRate: number,
    experiencePoints: number,
    userPermission: number,
    exposureAllowed: number,
    tagsEarned: string,
    levelAchieved: CommunityLevel,
    levelChallenged: CommunityLevel,
    outstandingTask: CommunityTask
}

export interface UserReturnId {
    getUserById: User
}

export default function useGetUserById() {
    const authToken = useAppSelector(s => s.auth.authKey)
    const cid = useAppSelector(s => s.dashboard.curServer?.communityId)
    const [lq] = useLazyQuery<UserReturnId, ReqVars>(GET_USER_GQL)
    return (uid: number) => {
        if (!cid){
            return false
        }
        return lq({variables: {ath: authToken, userId: uid, communityId: cid}}).then(r => {
            if (r.error){
                throw r.error
            } else {
                if (r.data){
                    return r.data
                } else {
                    return false
                }
            }
        }).catch(e => {
            console.log(e)
            return false;
        })
    }
}

