import {Button, Card, Checkbox, FormControlLabel, Grid, styled, TextField, Typography} from "@mui/material";
import {Community as ICommunity} from "../../../hooks/useCommunity";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import React, {useEffect, useState} from "react";
import useSaveCommunity, {SaveCommunityStatus} from "../../../hooks/useSaveCommunity";
import {useNavigate} from "react-router-dom";
import MultipleSelectChip from "./RoleSelector";
import {communitySlice} from "../../../redux/store";

export type formVal = ICommunity

const StyledCard = styled(Card)({
    height: "100%"
})

const Community = () => {
    const curState = useAppSelector(s => s.dashboard.curServer)
    const save = useSaveCommunity()
    const navigate = useNavigate()
    const [emesg, setEmesg] = useState("")
    const dispatch = useAppDispatch()

    const [state, setState] = useState<formVal>({
        communityId: 0,
        communityName: "",
        botName: "",
        communityType: 0,
        connectionKey: "",
        defaultUserStatus: 0,
        gainRate: 0,
        profileDuration: 0,
        taskDelayDays: 0,
        challengeMessage: "",
        tagGameRoles: "",
        tagExposure: "",
        tagModerator: "",
        tagAdmin: "",
        chanNotification: "",
        chanModerator: "",
        chanJail: "",
        metaData: "",
        isPremiumCommunity: 0,
        interactionPointsDelay: 0,
        gameName: "",
        installedGameName: "",
        isPremiumGame: 0,
        shareStatus: 0,
        sharedGameDescription: ""
    })

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(communitySlice.actions.setSaveCommunity(true))
        setState(s => ({...s, [e.target.name]: e.target.value}))
    }
    const onCheckUserStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(communitySlice.actions.setSaveCommunity(true))
        if (e.target.checked) {
            setState(s => ({...s, defaultUserStatus: 0}))
        } else {
            setState(s => ({...s, defaultUserStatus: 3}))
        }
    }
    const onCheckShareStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(communitySlice.actions.setSaveCommunity(true))
        if (e.target.checked) {
            setState(s => ({...s, shareStatus: 1}))
        } else {
            setState(s => ({...s, shareStatus: 0}))
        }
    }

    const onSubmit = () => {
        save(state).then(v => {
            switch (v) {
                case SaveCommunityStatus.ALREADY_LOGGED_OUT: {
                    navigate("/")
                    break
                }
                case SaveCommunityStatus.NO_RIGHT: {
                    setEmesg("You do not have privileges to edit.")
                    break
                }
                case SaveCommunityStatus.UNKNOWN: {
                    setEmesg("Unknown error")
                    break
                }
                case SaveCommunityStatus.NETWORK: {
                    setEmesg("Network error")
                    break
                }
                case SaveCommunityStatus.OK: {
                    setEmesg("Successfully updated.")
                    dispatch(communitySlice.actions.setSaveCommunity(false))
                    dispatch(communitySlice.actions.setSaveCommunityStatus(false))
                    break
                }
                case SaveCommunityStatus.NO_CHANGE: {
                    dispatch(communitySlice.actions.setSaveCommunity(false))
                    dispatch(communitySlice.actions.setSaveCommunityStatus(false))
                    setEmesg("No changes were made.")
                    break
                }
            }
        })
    }

    useEffect(() => {
        if (curState !== null) {
            setState(curState)
        }
    }, [curState])

    if (!curState) {
        return <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            <i>Select Community to show details</i>
        </Typography>
    }


    return <Grid container gap={2} direction={"column"} alignItems={"stretch"}>
        <Grid container direction={"row"} alignItems={"stretch"} justifyContent={"space-evenly"} gap={2}>
            <Grid>
                <StyledCard>
                    <Grid container direction={"column"} alignItems={"center"}
                          justifyContent={"center"} p={2} gap={2}>
                        <TextField size={"small"} onChange={onChange} name={"chanModerator"} value={state.chanModerator}
                                   label={"Moderation Channel"}/>
                        <TextField size={"small"} onChange={onChange} name={"chanNotification"}
                                   value={state.chanNotification} label={"Task Assignment Channel"}/>
                        <TextField size={"small"} onChange={onChange} name={"chanJail"} value={state.chanJail}
                                   label={"Jail Channel"}/>
                    </Grid>
                </StyledCard>
            </Grid>
            <Grid>
                <StyledCard>
                    <Grid container direction={"column"} alignItems={"center"}
                          justifyContent={"center"} p={2} gap={2}>
                        <TextField size={"small"} onChange={onChange} name={"gainRate"} value={state.gainRate}
                                   label={"Gain Rate"}/>
                        <TextField size={"small"} onChange={onChange} name={"taskDelayDays"} value={state.taskDelayDays}
                                   label={"Task Delay Days"}/>
                        <TextField size={"small"} onChange={onChange} name={"interactionPointsDelay"}
                                   value={state.interactionPointsDelay} label={"Interaction Points Delay"}/>
                    </Grid>
                </StyledCard>
            </Grid>
            <Grid>
                <StyledCard>
                    <Grid container direction={"column"} alignItems={"center"}
                          justifyContent={"center"} p={2} gap={2}>
                        <TextField size={"small"} onChange={onChange} name={"botName"} value={state.botName}
                                   label={"Bot's Nickname"}/>
                        <TextField size={"small"} onChange={onChange} name={"installedGameName"}
                                   value={state.installedGameName} label={"Installed Game Name (if any)"}
                                   disabled={true}/>
                        <TextField size={"small"} onChange={onChange} name={"gameName"} value={state.gameName}
                        label={"Game Name"}/>
                        <FormControlLabel
                            control={<Checkbox onChange={onCheckShareStatus} checked={state.shareStatus !== 0}/>}
                            label={"Game is shared?"}/>
                        <TextField size={"medium"} onChange={onChange} name={"challengeMessage"}
                                   value={state.challengeMessage} label={"Challenge Message"}
                                   multiline={true} fullWidth={true}/>
                        <FormControlLabel
                            control={<Checkbox onChange={onCheckUserStatus} checked={state.defaultUserStatus === 0}
                                               indeterminate={state.defaultUserStatus !== 0 && state.defaultUserStatus !== 3}/>}
                            value={state.defaultUserStatus} label="New users automatically start game play?"/>
                    </Grid>
                </StyledCard>
            </Grid>
            <Grid>
                <StyledCard>
                    <Grid container direction={"column"} alignItems={"center"}
                          justifyContent={"center"} p={2} gap={2}>
                        <MultipleSelectChip label={"Moderators' Roles"} selectedRoles={state.tagModerator}
                                            name={"tagModerator"} setState={setState}/>
                        <MultipleSelectChip label={"Game admins' Roles"} selectedRoles={state.tagAdmin}
                                            name={"tagAdmin"} setState={setState}/>
                        <MultipleSelectChip label={"Identity Exposure Roles"} selectedRoles={state.tagExposure}
                                            name={"tagExposure"} setState={setState}/>
                        <MultipleSelectChip label={"Gameplay Roles"} selectedRoles={state.tagGameRoles}
                                            name={"tagGameRoles"} setState={setState}/>
                    </Grid>
                </StyledCard>
            </Grid>


        </Grid>
        <Grid container direction={"row"} alignItems={"center"} justifyContent={"flex-end"} p={2} gap={2}>
            {/*for future use*/}
            {/*<Grid>*/}
            {/*    <Button size={"large"} disabled>Delete</Button>*/}
            {/*</Grid>*/}
            <Grid item hidden={!emesg} alignSelf={"center"}>
                <Typography variant={"subtitle2"} color={"error"}>{emesg}</Typography>
            </Grid>
            <Grid alignSelf={"flex-end"}>
                <Button variant={"contained"} onClick={onSubmit} size={"large"}>Save</Button>
            </Grid>
        </Grid>
    </Grid>
}

export default Community;
