import {Button, Card, Grid, Typography} from "@mui/material";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {levelSlice} from "../../../redux/store";
import AddIcon from "@mui/icons-material/Add";

export default function CreateTaskButton() {
    const s = useAppSelector(s => !((s.levels.currentLevel === null) || (s.levels.currentTaskGroup === null)))
    const curTask = useAppSelector(s => s.levels.currentTask)
    const dispatch = useAppDispatch()

    const saveTaskState = useAppSelector(s => s.levels.unsavedTask)
    const saveLevelState = useAppSelector(s => s.levels.unsavedLevel)
    const saveTaskGroupState = useAppSelector(s => s.levels.unsavedTaskGroup)

    const ocf = () => {
        if(saveTaskState) {
            dispatch(levelSlice.actions.setSaveTaskStatus(true))
        }
        else if(saveTaskGroupState) {
            dispatch(levelSlice.actions.setSaveTaskGroup(true))
        }
        else if(saveLevelState) {
            dispatch(levelSlice.actions.setSaveLevel(true))
        }
        else {
            if (curTask !== null){
                dispatch(levelSlice.actions.setCurrentTask(null))
            }
            dispatch(levelSlice.actions.setOpenEditLevelDrawer(false))
            dispatch(levelSlice.actions.setOpenEditTaskGroupDrawer(false))
            dispatch(levelSlice.actions.setOpenEditTaskDrawer(true))
        }
    }

    if (!s){
        return null
    }

    return <Grid>
        <Card variant={"outlined"} sx={{padding: 1}}>
        <Button onClick={ocf} size={"small"}>
            <Typography variant={"body1"}>{<AddIcon/>}</Typography>
        </Button>
    </Card>
        </Grid>
}