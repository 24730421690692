import useDefinedGames from "../../../hooks/useDefinedGames";
import {FormControlLabel, Grid, Radio, TableCell, TableRow, Typography} from "@mui/material";
import React from "react";

const GamesTable = () => {
    const g_list = useDefinedGames()

    if (!g_list || g_list.length === 0) {
        return <Grid>
            <Typography sx={{fontSize: 13, padding: 2}} color="text.secondary" gutterBottom>
                <i>No Games available</i>
                <br/>
                <i>Shared games will appear here</i>
            </Typography>
        </Grid>
    }
    return <Grid>
        {
            g_list.map((s, k) => {
                return <TableRow key={k}>
                    <TableCell>
                        <FormControlLabel value={s.gameId} control={<Radio/>} label={s.gameName}/>
                    </TableCell>
                    <TableCell>
                        {s.gameDescription}
                    </TableCell>
                </TableRow>
            })
        }
    </Grid>
}

export default GamesTable;