import {
    Card,
    Divider,
    Grid,
    Paper,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import useGetUserById, {User, UserReturnId} from "../../../hooks/useGetUserById";
import {useAppSelector} from "../../../redux/hooks";
import LeaderBoard from "./LeaderBoard";
import TaskHistory from "./TaskHistory";
import {CommunityLevel, CommunityTask} from "../../../hooks/useGetLevels";
import SearchResults from "./SearchResults";
import ViewRoles from "./ViewRoles";

export type formVal = User

const StyledCard = styled(Card)({
    height: "100%"
})

const Members = () => {
    const curState = useAppSelector(s => s.dashboard.curServer)
    const curNickname = useAppSelector(s => s.member.curNickname)
    const getUserById = useGetUserById()
    const [state, setState] = useState<formVal>({
        userId: curNickname === null ? 0 : curNickname.user.userId,
        communityId: curNickname === null ? 0 : curNickname.user.communityId,
        createDate: curNickname === null ? "" : curNickname.user.createDate,
        startDate: curNickname === null ? "" : curNickname.user.startDate,
        endDate: curNickname === null ? "" : curNickname.user.endDate,
        profileSyncDate: curNickname === null ? "" : curNickname.user.profileSyncDate,
        endJailDate: curNickname === null ? "" : curNickname.user.endJailDate,
        userStatus: curNickname === null ? 0 : curNickname.user.userStatus,
        userNickname: curNickname === null ? "" : curNickname.user.userNickname,
        percentGainRate: curNickname === null ? 0 : curNickname.user.percentGainRate,
        experiencePoints: curNickname === null ? 0 : curNickname.user.experiencePoints,
        userPermission: curNickname === null ? 0 : curNickname.user.userPermission,
        exposureAllowed: curNickname === null ? 0 : curNickname.user.exposureAllowed,
        tagsEarned: curNickname === null ? "" : curNickname.user.tagsEarned,
        levelAchieved: null as unknown as CommunityLevel,
        levelChallenged: null as unknown as CommunityLevel,
        outstandingTask: null as unknown as CommunityTask
    })

    const initState = {
        userId: curNickname === null ? 0 : curNickname.user.userId,
        communityId: curNickname === null ? 0 : curNickname.user.communityId,
        createDate: curNickname === null ? "" : curNickname.user.createDate,
        startDate: curNickname === null ? "" : curNickname.user.startDate,
        endDate: curNickname === null ? "" : curNickname.user.endDate,
        profileSyncDate: curNickname === null ? "" : curNickname.user.profileSyncDate,
        endJailDate: curNickname === null ? "" : curNickname.user.endJailDate,
        userStatus: curNickname === null ? 0 : curNickname.user.userStatus,
        userNickname: curNickname === null ? "" : curNickname.user.userNickname,
        percentGainRate: curNickname === null ? 0 : curNickname.user.percentGainRate,
        experiencePoints: curNickname === null ? 0 : curNickname.user.experiencePoints,
        userPermission: curNickname === null ? 0 : curNickname.user.userPermission,
        exposureAllowed: curNickname === null ? 0 : curNickname.user.exposureAllowed,
        tagsEarned: curNickname === null ? "" : curNickname.user.tagsEarned,
        levelAchieved: null as unknown as CommunityLevel,
        levelChallenged: null as unknown as CommunityLevel,
        outstandingTask: null as unknown as CommunityTask
    }

    const [search, setSearch] = useState({searchString: ""})
    // const getVerifyTask = useVerifyTask(state.userId)
    useEffect(() => {
        setState(initState)
        setSearch({searchString: ""})
    }, [curState])

    const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(s => ({...s, [e.target.name]: e.target.value}))
    }

    // for future use
    // function onSubmitVerifyTask() {
    //     getVerifyTask().then(r => {
    //         switch (r) {
    //             case VerifyTaskStatus.OK:
    //                 break
    //             case VerifyTaskStatus.UNKNOWN: {
    //                 alert("error")
    //                 break
    //             }
    //         }
    //     })
    // }

    function getUserDetailsById(uid: number) {

        const rv = getUserById(uid)
        if (rv) {
            rv.then(r => {
                    if (r) {
                        setState((r as UserReturnId).getUserById)
                    }
                }
            )
        }
    }

    if (!curState) {
        return <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
            <i>Select Community to show details</i>
        </Typography>
    }

    const setUid = (userId: number) => {
        getUserDetailsById(userId)
    }

    return <Grid container gap={2} direction={"row"} alignItems={"flex-start"}>
        <Grid>
            <Card sx={{padding: 2}}>
                <Grid container gap={2} direction={"column"} justifyContent={"space-between"}>
                    <Grid>
                        <Card variant={"outlined"} sx={{padding: 2}}>
                            <Grid container direction={"column"} alignItems={"center"}
                                  justifyContent={"center"} gap={1}>
                                <Grid alignSelf={"flex-start"}>
                                    <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
                                        Search Box
                                    </Typography>
                                </Grid>
                                <TextField size={"small"} onChange={onSearch} name={"searchString"}
                                           label={"Username or Nickname"} value={search.searchString}>
                                </TextField>
                                <SearchResults nickname={search.searchString} selectedRowId={state.userId}
                                               setSelectedRowId={setUid}/>
                            </Grid>
                        </Card>
                    </Grid>
                    <Grid>
                        <Card variant={"outlined"} sx={{padding: 2}}>
                            <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
                                Leaderboard
                            </Typography>
                            <Grid>
                                <LeaderBoard selectedRowId={state.userId} setSelectedRowId={setUid}/>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>
            </Card>
        </Grid>
        <Grid sm={8} container direction={"row"} alignItems={"stretch"} justifyContent={"space-evenly"} gap={2}>
            <Grid>
                <StyledCard>
                    <Grid container direction={"column"} alignItems={"flex-start"}
                          justifyContent={"center"} p={2} gap={2} sx={{minWidth: 200}}>
                        <Typography sx={{fontSize: 14}} color="text.secondary" align={"left"}>
                            <i>Create Date</i>
                        </Typography>
                        <Typography
                            variant={"body2"}>{state.createDate !== "0001-01-01 00:00:00" ? state.createDate : "-"}</Typography>
                        <Divider orientation="horizontal" flexItem/>

                        <Typography sx={{fontSize: 14}} color="text.secondary">
                            <i>Start Date</i>
                        </Typography>
                        <Typography
                            variant={"body2"}>{state.startDate !== "0001-01-01 00:00:00" ? state.startDate : "-"}</Typography>
                        <Divider orientation="horizontal" flexItem/>

                        <Typography sx={{fontSize: 14}} color="text.secondary">
                            <i>End Date</i>
                        </Typography>
                        <Typography
                            variant={"body2"}>{state.endDate !== "0001-01-01 00:00:00" ? state.endDate : "-"}</Typography>
                        <Divider orientation="horizontal" flexItem/>

                        <Typography sx={{fontSize: 14}} color="text.secondary">
                            <i>Jail End Date</i>
                        </Typography>
                        <Typography
                            variant={"body2"}>{state.endJailDate !== "0001-01-01 00:00:00" ? state.endJailDate : "-"}</Typography>
                    </Grid>
                </StyledCard>
            </Grid>
            <Grid>
                <StyledCard>
                    <Grid container direction={"column"} alignItems={"flex-start"}
                          justifyContent={"center"} p={2} gap={1} sx={{minWidth: 200}}>
                        <Typography sx={{fontSize: 14}} color="text.secondary">
                            <i>Gain Rate</i>
                        </Typography>
                        <Typography variant={"body2"}>{state.percentGainRate}</Typography>
                        <Divider orientation="horizontal" flexItem/>

                        <Typography sx={{fontSize: 14}} color="text.secondary">
                            <i>XP</i>
                        </Typography>
                        <Typography variant={"body2"}>{state.experiencePoints}</Typography>
                        <Divider orientation="horizontal" flexItem/>

                        <Typography sx={{fontSize: 14}} color="text.secondary">
                            <i>Permissions</i>
                        </Typography>
                        <Typography variant={"body2"}>{state.userPermission}</Typography>
                        <Divider orientation="horizontal" flexItem/>

                        <Typography sx={{fontSize: 14}} color="text.secondary">
                            <i>Roles Earned</i>
                        </Typography>

                        <ViewRoles selectedRoles={state.tagsEarned} name={"tagEarned"} setState={setState}/>

                    </Grid>
                </StyledCard>
            </Grid>
            <Grid>
                <StyledCard>
                    <Grid container direction={"column"} alignItems={"flex-start"} sx={{minWidth: 200}}
                          justifyContent={"center"} p={2} gap={2}>
                        <Typography sx={{fontSize: 14}} color="text.secondary">
                            <i>Nickname</i>
                        </Typography>
                        <Typography
                            variant={"body2"}>{state.userNickname}</Typography>
                        <Divider orientation="horizontal" flexItem/>

                        <Typography sx={{fontSize: 14}} color="text.secondary">
                            <i>Level Achieved</i>
                        </Typography>
                        <Typography
                            variant={"body2"}>{state?.levelAchieved !== null ? state.levelAchieved.levelName : ""}</Typography>
                        <Divider orientation="horizontal" flexItem/>

                        <Typography sx={{fontSize: 14}} color="text.secondary">
                            <i>Level Challenged</i>
                        </Typography>
                        <Typography
                            variant={"body2"}>{state.levelChallenged !== null ? state.levelChallenged.levelName : ""}</Typography>
                        <Divider orientation="horizontal" flexItem/>

                        <Typography sx={{fontSize: 14}} color="text.secondary">
                            <i>Outstanding Task</i>
                        </Typography>
                        <Typography
                            variant={"body2"}>{state.outstandingTask !== null ? state.outstandingTask.taskAnswer : ""}</Typography>
                    </Grid>
                    {/*for possible future use*/}
                    {/*<Grid container direction={"row"} justifyContent={"space-between"} p={2} gap={2}>*/}
                    {/*    <Grid>*/}
                    {/*        <Button onClick={onSubmitVerifyTask} variant={"outlined"} size={"small"} sx={{width: 80}}*/}
                    {/*                disabled>Verify Task</Button>*/}
                    {/*    </Grid>*/}
                    {/*    <Grid>*/}
                    {/*        <Button variant={"outlined"} size={"small"} sx={{width: 80}} disabled>Fail Task</Button>*/}
                    {/*    </Grid>*/}
                    {/*</Grid>*/}
                </StyledCard>
            </Grid>
            <Grid>
                <TableContainer component={Paper}>
                    <Table stickyHeader={true}>
                        <TableHead>
                            <TableRow>
                                <TableCell><b>History ID</b></TableCell>
                                <TableCell><b>Task ID</b></TableCell>
                                <TableCell><b>Date Issued</b></TableCell>
                                <TableCell><b>Date Achieved</b></TableCell>
                                <TableCell><b>Rejected Count</b></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TaskHistory uid={state.userId}/>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    </Grid>
}

export default Members;