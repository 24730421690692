import {gql, useQuery} from "@apollo/client";
import {useAppSelector} from "../redux/hooks";

export const GET_LEVELS_GQL = gql`query getLevelsAndTasks($ath: String!, $cid: Int!) {
  getLevelsAndTasks(authToken: $ath, communityId: $cid) {
    communityLevels {
      levelId
      communityId
      levelName
      levelAdjective
      xpThreshold
      achievementURL
      achievementTags
      }
    taskGroup {
      groupId
      communityId
      groupName
      groupTags
      activeFlag
    }
    taskList {
      taskId
      taskGroupId
      taskLevelId
      taskDescription
      taskExposureFlag
      taskType
      taskAnswer
    }
  }
}`

type GetLevelsInput = { ath: string, cid: number }
export type CommunityLevel = {
    levelId: number; levelName: string; levelAdjective: string; xpThreshold: number; achievementURL: string; achievementTags: string;
}
export type CommunityTaskGroup = {
    groupId: number; groupName: string; groupTags: string; activeFlag: number;
}
export interface CommunityTask {
    taskId: number;
    taskGroupId: number;
    taskLevelId: number;
    taskDescription: string;
    taskExposureFlag: boolean
    taskType: string
    taskAnswer: string
}


type GetLevelsOutput = {
    getLevelsAndTasks: {
        communityLevels: Array<CommunityLevel>, taskGroup: Array<CommunityTaskGroup>, taskList: Array<CommunityTask>
    }
}

export default function useGetLevels(cid: number) {
    const authToken = useAppSelector(s => s.auth.authKey)
    const {
        loading, error, data
    } = useQuery<GetLevelsOutput, GetLevelsInput>(GET_LEVELS_GQL, {variables: {ath: authToken, cid: cid}})
    if (loading && !error) {
        return null
    }
    if (error) {
        return -1
    }
    if (data) {
        return data.getLevelsAndTasks
    }
    return -1
}
