import React from 'react';
import ReactDOM from 'react-dom/client';
import App from "./App";
import {Provider} from "react-redux";
import {store} from "./redux/store";
import {ApolloClient, ApolloProvider, InMemoryCache} from "@apollo/client";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import CallbackPage from "./pages/Callback";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import CopyGamePage from "./components/dashboard/games/CopyGamePage";


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const client = new ApolloClient({
    uri: process.env.REACT_APP_GRAPHQL_SERVER,
    cache: new InMemoryCache()
});

const theme = createTheme({

    palette:{
        mode: "dark",
        primary: {
            main: "#b81a9a"
        },
        secondary: {
            main: "#f50057"
        },
        background: {
            default: "#313131",
            paper: "#212121"
        }
    },
    typography: {
        fontFamily: ['Roboto','Arial','Helvetica', 'sans-serif'
        ].join(','),
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 780,
            lg: 900,
            xl: 1536,
        }
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                '*::-webkit-scrollbar': {
                    width: '0.6em'
                },
                '*::-webkit-scrollbar-track': {
                    '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
                },
                '*::-webkit-scrollbar-thumb': {
                    backgroundColor: "#3e3e3e",
                    borderRadius: '4px'
                }
            }

        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    "&$selected": {
                        backgroundColor: '#4C4A4B',
                    },
                },
            }

        },
        MuiInput: {
            styleOverrides: {
                input: {
                    "&::placeholder": {
                        color: "gray"
                    },
                    color: "white",
                }
            }

        },
        MuiPaper: {
            styleOverrides:{
                root:{
                    backgroundColor: "#1f1f1f",
                }
            }

        },
        MuiCard: {
            styleOverrides: {
                root: {
                    backgroundColor: "#1f1f1f"
                }
            }
        },

        MuiToolbar: {
            styleOverrides: {
                dense: {
                    height: 64,
                    minHeight: 64
                }
            }
        },
    }
});

const Root = () => {
    return <React.StrictMode>
        <Provider store={store}>
            <ApolloProvider client={client}>
                <ThemeProvider theme={theme}>
                    <BrowserRouter>
                        <App/>
                        <Routes>
                            <Route path={"/"} element={<Login/>}/>
                            <Route path={"/dashboard"} element={<Dashboard/>}/>
                            <Route path={"/callback/:communityType"} element={<CallbackPage/>}/>
                            <Route path={"/botSuccess"} element={<Navigate to={"/dashboard"} replace/>}/>
                            <Route path={"/dashboard/copygame"} element={<CopyGamePage/>}/>
                        </Routes>
                    </BrowserRouter>
                </ThemeProvider>
            </ApolloProvider>
        </Provider>
    </React.StrictMode>
}

root.render(
    <Root/>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
