import * as React from 'react';
import {Dispatch, SetStateAction} from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import useRoles, {CommunityRole, parseRoleNames} from "../../../hooks/useRoles";
import {Community as ICommunity} from "../../../hooks/useCommunity";
import {Chip} from "@mui/material";
import {HighlightOffRounded} from "@mui/icons-material";
import {useAppDispatch} from "../../../redux/hooks";
import {communitySlice} from "../../../redux/store";
import {TaskGroupIn} from "../../../hooks/useSaveTaskGroup";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: "10em"
        },
    },
};

const RenderItems = (selected: string[], deleteItem: (o: CommunityRole) => void) => {
    return <Box sx={{display: "flex", flexWrap: 'wrap', gap: 0.5}}>
        {selected.map((s, k) => {
            const obj: CommunityRole = JSON.parse(s) // role is of form: {"id": "123", "name": "foobar"}
            const onMousedDown = (e: React.MouseEvent<SVGSVGElement>) => { // note the usage of onMouseDown. It is because onClick was having issues with event bubbline
                deleteItem(obj)
                if (selected.length > 1) {
                    e.stopPropagation()
                }
            }
            return <Chip key={k} label={obj.name} onDelete={() => {
            }} deleteIcon={<HighlightOffRounded onMouseDown={onMousedDown}/>} variant={"outlined"}/>
        })}
    </Box>
}

const generateRoleString = (roles: string[]) => {
    const roleObjs: CommunityRole[] = roles.map(r => JSON.parse(r)) // roles is of form: [{"id": "123", "name": "foobar"},...]
    return roleObjs.map(s => `${s.id}:${s.name}`).join(", ")
}

interface MultiSelectProps {
    label: string,
    selectedRoles: string,
    name: string,
    setState: Dispatch<SetStateAction<ICommunity>>|Dispatch<SetStateAction<TaskGroupIn>>
}


export default function MultipleSelectChip(props: MultiSelectProps) {
    const names = useRoles()
    const curSelected = parseRoleNames(props.selectedRoles)
    const values = curSelected.map(s => JSON.stringify(s))
    const dispatch = useAppDispatch()
    const onChange = (e: SelectChangeEvent<string[]>) => {
        dispatch(communitySlice.actions.setSaveCommunity(true))
        const value = e.target.value
        if (typeof value === 'string') { // needed to be done this way for multiselect. needed to be done to satisfy typescript
            console.log("str", value)
        } else {
            props.setState((s: any) => {
                return {...s, [props.name]: generateRoleString(value)}
            })
        }
    }
    const deleteItem = (item: CommunityRole) => {
        const selectedRoles = parseRoleNames(props.selectedRoles)
        const selectedRolesAfterDelete = selectedRoles.filter(s => s.id !== item.id).map(s => JSON.stringify(s))
        props.setState((s: any) => {
            return {...s, [props.name]: generateRoleString(selectedRolesAfterDelete)}
        })
    }

    const renderSelectedWithDeleteItem = (selected: string[]) => {
        return RenderItems(selected, deleteItem)
    }

    return <Box>
        <FormControl fullWidth>
            <InputLabel id={"demo-simple-select-label-" + props.name}>{props.label}</InputLabel>
            <Select
                labelId={"demo-simple-select-label-" + props.name}
                id={"demo-simple-select-" + props.name}
                size={"medium"}
                sx={{width: "15rem"}}
                value={values}
                multiple
                onChange={onChange}
                MenuProps={MenuProps}
                renderValue={renderSelectedWithDeleteItem}
            >
                {names.map((v, k) => {

                    return <MenuItem value={JSON.stringify(v)} key={k}>
                        {v.name}
                    </MenuItem>
                })}
            </Select>
        </FormControl>
    </Box>
}
