import {useAppSelector} from "../redux/hooks";
import {gql, useMutation} from "@apollo/client";

const CREATE_ACCOUNT_GQL = gql`mutation createAccount($ath: String!, $uname: String!, $passwd: String!){
  createAccount(authToken: $ath, username: $uname, passwd: $passwd)
}`

interface CreateAccountRv {
    createAccount: {
        userId: string,
    }
}

type CreateAccountParams = {
    ath: string,
    uname: string,
    passwd: string
}

export enum CreateAccountStatus {
    OK,
    USER_EXISTS,
    POOR_USERNAME,
    POOR_PASSWD,
    DB_ERROR,
    USER_LOGGED_IN,
    UNKNOWN
}

export default function useCreateAccount() {
    const authKey = useAppSelector(state => state.auth.authKey)
    const [createAccount] = useMutation<CreateAccountRv, CreateAccountParams>(CREATE_ACCOUNT_GQL)
    return (uname: string, passwd: string) => {
        if ((!uname || !passwd)) {
            return new Promise(() => CreateAccountStatus.UNKNOWN);
        }
        return createAccount({
            variables: {
                uname: uname,
                passwd: passwd,
                ath: authKey
            }
        }).then(v => {

            if (v.errors) {
                throw v.errors[0]
            }
            const data = v.data
            if (data) {
                if (data.createAccount !== null) {
                    if (data.createAccount) {
                        return CreateAccountStatus.OK
                    } else {
                        return CreateAccountStatus.UNKNOWN
                    }
                }
            } else {
                return CreateAccountStatus.UNKNOWN
            }
        }).catch(e => {
            const emesg = e.toString()
            if (emesg.includes("DBINT")) {
                return CreateAccountStatus.DB_ERROR
            } else if (emesg.includes("CAULEN")) {
                return CreateAccountStatus.POOR_USERNAME
            } else if (emesg.includes("CAPWD")) {
                return CreateAccountStatus.POOR_PASSWD
            } else if (emesg.includes("CAUEX")) {
                return CreateAccountStatus.USER_EXISTS
            } else if (emesg.includes("CADUP")) {
                return CreateAccountStatus.USER_LOGGED_IN
            } else return CreateAccountStatus.UNKNOWN
        });

    }
}