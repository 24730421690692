import {gql, useMutation} from "@apollo/client";
import {useAppSelector} from "../redux/hooks";
import {GET_LEVELS_GQL} from "./useGetLevels";

const DELETE_TASK_MUTATION = gql`mutation deleteGroup($auth: String!, $groupId: Int!) {
    deleteGroup(authToken: $auth, groupId: $groupId)
    }`

interface DeleteGroupVariables {
    auth: string,
    groupId: number
}

interface DeleteGroupReturn {
    deleteGroup: number
}

export enum DeleteGroupStatus {
    OK,
    NO_RIGHT,
    TASKS_REMAINING,
    UNKNOWN
}

export default function useDeleteGroup() {
    const [deleteGroup] = useMutation<DeleteGroupReturn, DeleteGroupVariables>(DELETE_TASK_MUTATION, {
        refetchQueries: [GET_LEVELS_GQL]
    })
    const auth = useAppSelector(s => s.auth.authKey)
    const groupId = useAppSelector(s => s.levels.currentTaskGroup?.groupId as number)

    return () => {
        return deleteGroup({variables: { groupId: groupId, auth: auth}}).then(r => {
            if(r.errors) {
                const e = r.errors[0]
                const emesg = e.toString()
                if (emesg.includes("INVCOM")) {
                    return DeleteGroupStatus.NO_RIGHT
                } else if(emesg.includes("NEMPTASK")) {
                    return DeleteGroupStatus.TASKS_REMAINING
                }
                return DeleteGroupStatus.UNKNOWN
            }
            if(r.data) {
                if(r.data.deleteGroup !== null) {
                    return DeleteGroupStatus.OK
                } else {
                    return DeleteGroupStatus.UNKNOWN
                }
            } else {
                return DeleteGroupStatus.UNKNOWN
            }
        }).catch(e => {
            const emesg = e.toString()
            if (emesg.includes("INVCOM")) {
                return DeleteGroupStatus.NO_RIGHT
            } else if(emesg.includes("NEMPTASK")) {
                return DeleteGroupStatus.TASKS_REMAINING
            }
            return DeleteGroupStatus.UNKNOWN
        })
    }
}