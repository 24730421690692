import {Button, Card, Grid, Typography} from "@mui/material";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {levelSlice} from "../../../redux/store";
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import React from "react";

export default function CreateLevelButton() {
    const curLevel = useAppSelector((s => s.levels.currentLevel))
    const dispatch = useAppDispatch()

    const saveTaskState = useAppSelector(s => s.levels.unsavedTask)
    const saveLevelState = useAppSelector(s => s.levels.unsavedLevel)
    const saveTaskGroupState = useAppSelector(s => s.levels.unsavedTaskGroup)

    const ocf_add = () => {
        if(saveTaskState) {
            dispatch(levelSlice.actions.setSaveTaskStatus(true))
        }
        else if(saveTaskGroupState) {
            dispatch(levelSlice.actions.setSaveTaskGroupStatus(true))
        }
        else if(saveLevelState) {
            dispatch(levelSlice.actions.setSaveLevelStatus(true))
        }
        else {
            dispatch(levelSlice.actions.setCurrentLevel(null))
            dispatch(levelSlice.actions.setOpenEditTaskDrawer(false))
            dispatch(levelSlice.actions.setOpenEditTaskGroupDrawer(false))
            dispatch(levelSlice.actions.setOpenEditLevelDrawer(true))
        }
    }
    const ocf_edit = () => {
        if(saveTaskState) {
            dispatch(levelSlice.actions.setSaveTaskStatus(true))
        }
        else if(saveTaskGroupState) {
            dispatch(levelSlice.actions.setSaveTaskGroupStatus(true))
        }
        else if(saveLevelState) {
            dispatch(levelSlice.actions.setSaveLevelStatus(true))
        }
        else {
            dispatch(levelSlice.actions.setOpenEditTaskDrawer(false))
            dispatch(levelSlice.actions.setOpenEditTaskGroupDrawer(false))
            dispatch(levelSlice.actions.setOpenEditLevelDrawer(true))
        }
    }


    return <Grid>
        <Card variant={"outlined"} sx={{padding: 1}}>
            <Button onClick={ocf_edit} size={"small"} disabled={curLevel === null}>
                <Typography variant={"body1"}>{<EditIcon/>}</Typography>
            </Button>
            <Button onClick={ocf_add} size={"small"}>
                <Typography variant={"body1"}>{<AddIcon/>}</Typography>
            </Button>
        </Card>
    </Grid>
}