import {
    Button,
    Grid,
    Paper,
    RadioGroup,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import React from "react";
import GamesTable from "./GamesTable";
import {useAppSelector} from "../../../redux/hooks";
import {useNavigate} from "react-router-dom";

const Games = () => {
    const curServer = useAppSelector(s=>s.dashboard.curServer)
    const navigate = useNavigate()
    const ocf_copy = () => {
        navigate("/dashboard/copygame")
    }

    if(!curServer) {
        return <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            <i>Select Community to show details</i>
        </Typography>
    }
    return <Grid container direction={"column"} gap={2}>
        <Grid>
            <TableContainer component={Paper}>
                <Table stickyHeader={true}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Game Name</TableCell>
                            <TableCell>Description</TableCell>
                        </TableRow>
                    </TableHead>
                    <RadioGroup>
                    <GamesTable/>
                    </RadioGroup>
                </Table>
            </TableContainer>
        </Grid>
        <Grid>
            <Button onClick={ocf_copy} variant={"contained"} disabled>Copy Game</Button>
        </Grid>
    </Grid>
}

export default Games;