import {Button, Dialog, Grid, Typography} from "@mui/material";
import React from "react";
import {useAppDispatch} from "../../redux/hooks";
import {communitySlice, levelSlice} from "../../redux/store";
import {WarningRounded} from "@mui/icons-material";

export default function UnsavedDialog({type, status}: { type: string, status: any }) {
    const dispatch = useAppDispatch()
    const ocf_cancel = () => {
        dispatch(levelSlice.actions.setSaveLevelStatus(false))
        dispatch(levelSlice.actions.setSaveTaskGroupStatus(false))
        dispatch(levelSlice.actions.setSaveTaskStatus(false))
        dispatch(communitySlice.actions.setSaveCommunityStatus(false))
    }
    const ocf_discard = () => {
        if(type === "Task") {
            dispatch(levelSlice.actions.setOpenEditTaskDrawer(false))
            dispatch(levelSlice.actions.setSaveTaskStatus(false))
            dispatch(levelSlice.actions.setSaveTask(false))
        }
        else if(type === "Level") {
            dispatch(levelSlice.actions.setOpenEditLevelDrawer(false))
            dispatch(levelSlice.actions.setSaveLevelStatus(false))
            dispatch(levelSlice.actions.setSaveLevel(false))

        }
        else if(type === "Task Group") {
            dispatch(levelSlice.actions.setOpenEditTaskGroupDrawer(false))
            dispatch(levelSlice.actions.setSaveTaskGroupStatus(false))
            dispatch(levelSlice.actions.setSaveTaskGroup(false))

        }
        else if(type === "Community") {
            dispatch(communitySlice.actions.setSaveCommunityStatus(false))
            dispatch(communitySlice.actions.setSaveCommunity(false))
        }
    }

    return <Dialog open={status}>
        <Grid container direction={"column"} gap={2} padding={2} justifyContent={"space-around"}>
            <Grid container direction={"row"} gap={1}>
                <WarningRounded/>
                <Typography variant={"h6"} alignItems={"center"}>
                    Caution</Typography>
            </Grid>
            <Grid>
                <Typography variant={"body1"} alignItems={"center"}
                            justifyContent={"space-evenly"}>
                    You have unsaved changes in {type} Editor.</Typography>
                <Typography variant={"body1"} alignItems={"center"}
                            justifyContent={"space-evenly"}>
                   Close the dialog box and save changes. </Typography>
            </Grid>
            <Grid container direction={"row"} gap={1} padding={1} justifyContent={"space-between"}>
                <Grid>
                    <Button onClick={ocf_discard}  color={"error"}>Discard</Button>
                </Grid>
                <Grid>
                    <Button onClick={ocf_cancel} variant={"contained"}>Go back</Button>
                </Grid>
            </Grid>
        </Grid>
    </Dialog>
}