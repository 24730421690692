import {gql, useMutation} from "@apollo/client";
import {useAppSelector} from "../redux/hooks";
import {Community, GET_COMMUNITIES_GQL} from "./useCommunity";

const SAVE_COMMUNITY_MUTATION = gql`mutation createUpdateCommunity($auth: String!, $data: CommunityIn!) {
createUpdateCommunity(authToken: $auth, communityData: $data)
}`

export interface SaveCommunityVariables {
    auth: string,
    data: Community
}

interface SaveCommunityReturn {
    createUpdateCommunity: number
}

export enum SaveCommunityStatus {
    OK,
    NO_CHANGE,
    NO_RIGHT,
    IAUTHTK,
    ALREADY_LOGGED_OUT,
    NETWORK,
    UNKNOWN,
}

export default function useSaveCommunity(): (s: Community) => Promise<SaveCommunityStatus> {
    const [community] = useMutation<SaveCommunityReturn, SaveCommunityVariables>(SAVE_COMMUNITY_MUTATION,
        {refetchQueries: [GET_COMMUNITIES_GQL]})

    const authKey = useAppSelector(s => s.auth.authKey)
    return (s: Community) => {
        return community({variables: { data: s, auth: authKey}}).then(r => {
            if (r.errors) {
                const e = r.errors[0]
                const emesg = e.toString()
                if (emesg.includes("INVCOM")) {
                    return SaveCommunityStatus.NO_RIGHT
                } else if (emesg.includes("IAUTHTK")) {
                    window.location.reload()
                } else if (emesg.includes("NOUSER")) {
                    return SaveCommunityStatus.ALREADY_LOGGED_OUT
                } else if (emesg.includes("CUDBFAIL")) {
                    return SaveCommunityStatus.NO_CHANGE
                }
                return SaveCommunityStatus.UNKNOWN
            }
            const data = r.data
            if (data) {
                if (data.createUpdateCommunity !== null) {
                    return SaveCommunityStatus.OK
                } else {
                    return SaveCommunityStatus.UNKNOWN
                }
            } else {
                return SaveCommunityStatus.UNKNOWN
            }
        }).catch(e => {
            const emesg = e.toString()
            if (emesg.includes("Failed to fetch")) {
                return SaveCommunityStatus.NETWORK
            } else if (emesg.includes("INVCOM")) {
                return SaveCommunityStatus.NO_RIGHT
            } else if (emesg.includes("IAUTHTK")) {
                window.location.reload()
                return SaveCommunityStatus.UNKNOWN
            } else if (emesg.includes("NOUSER")) {
                return SaveCommunityStatus.ALREADY_LOGGED_OUT
            } else if (emesg.includes("CUDBFAIL")) {
                return SaveCommunityStatus.NO_CHANGE
            }
            else {
                return SaveCommunityStatus.UNKNOWN
            }
        })
    }
}

