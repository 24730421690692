import {gql, useMutation} from "@apollo/client";
import {useAppSelector} from "../redux/hooks";

const LOGOUT_MUTATION = gql`mutation logout($auth: String!) {
    logout(authToken: $auth)
    }`

interface LogoutVariables {
    auth: string
}

interface LogoutReturn {
    logout: number
}

export enum LogoutStatus {
    OK,
    ALREADY_LOGGED_OUT,
    UNKNOWN,
    NETWORK

}

export default function useLogout() {
    const [logout] = useMutation<LogoutReturn, LogoutVariables>(LOGOUT_MUTATION)
    const authKey = useAppSelector(s => s.auth.authKey)
    return async () => {


        try {
            let r = await logout(({variables: {auth: authKey}}));
            if (r.errors?.length) {
                const e = r.errors[0]
                const emesg = e.toString()
                console.log(e)
                if (emesg.includes("NOUSER")) {
                    return LogoutStatus.ALREADY_LOGGED_OUT
                } else if (emesg.includes("IAUTHTK")) {
                    window.location.reload()
                }
                return LogoutStatus.UNKNOWN
            }
            const data = r.data
            if (data) {
                if (data.logout !== null) {
                    return LogoutStatus.OK
                } else {
                    return LogoutStatus.UNKNOWN
                }
            } else {
                return LogoutStatus.UNKNOWN
            }
        } catch (e1: any) {
            const emesg = e1.toString()
            if (emesg.includes("Failed to fetch")) {
                return LogoutStatus.NETWORK
            } else {
                return LogoutStatus.UNKNOWN
            }
        }
    }
}