import useGetUserByNickname from "../../../hooks/useGetUserByNickname";
import {Grid, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography} from "@mui/material";
import React, {Dispatch} from "react";

export default function SearchResults({nickname, selectedRowId, setSelectedRowId}:
                                          { nickname: string, selectedRowId: number, setSelectedRowId: Dispatch<number> }) {

    const UserList = useGetUserByNickname(nickname)
    if (!UserList || nickname == "") {
        return <Typography sx={{fontSize: 13}} color="text.secondary" gutterBottom>
            <i>No results</i>
        </Typography>
    }
    return <Grid container direction={"column"} alignItems={"center"}
                 justifyContent={"flex-start"} p={1} gap={1}>

        <Grid sx={{minWidth: 200, height: "20vh"}}>
            <form>
                <TableContainer component={Paper} sx={{maxHeight: "30vh", overflowX: "hidden", overflowY: "scroll"}}>
                    <Table sx={{maxWidth: 200}} stickyHeader={true} size={"small"}>
                        <TableBody>
                            {UserList.map((v, i) => {
                                return <TableRow selected={v.user.userId == selectedRowId}
                                                 onClick={() => setSelectedRowId(v.user.userId)}>
                                    <TableCell>{v.user.userNickname}<br/> <i>{v.username}</i></TableCell>
                                </TableRow>
                            })}
                        </TableBody>

                    </Table>
                </TableContainer>
            </form>
        </Grid>
    </Grid>
}