import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import {Grid, Select, SelectChangeEvent, Typography} from "@mui/material";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {communitySlice, dashboardSlice, levelSlice} from "../../redux/store";
import useCommunities, {Community} from "../../hooks/useCommunity";

export default function ServerMenu() {
    const s_list = useCommunities()
    const state = useAppSelector(s => s.dashboard.curServer)

    const saveTaskState = useAppSelector(s => s.levels.unsavedTask)
    const saveLevelState = useAppSelector(s => s.levels.unsavedLevel)
    const saveTaskGroupState = useAppSelector(s => s.levels.unsavedTaskGroup)
    const saveCommunityState = useAppSelector(s => s.community.unsavedCommunity)

    const dispatch = useAppDispatch()
    const ocf = (e: SelectChangeEvent) => {
        let selected: Community | null | undefined = s_list.find(v => {
            return v.communityId == parseInt(e.target.value)
        })
        if (selected === undefined) {
            selected = null
        }
        if(saveTaskState) {
            dispatch(levelSlice.actions.setSaveTaskStatus(true))
        }
        else if(saveTaskGroupState) {
            dispatch(levelSlice.actions.setSaveTaskGroupStatus(true))
        }
        else if(saveLevelState) {
            dispatch(levelSlice.actions.setSaveLevelStatus(true))
        }
        else if(saveCommunityState) {
            dispatch(communitySlice.actions.setSaveCommunityStatus(true))
        }
        else {
            dispatch(dashboardSlice.actions.setCurServer(selected))
            dispatch(levelSlice.actions.setCurrentLevel(null))
            dispatch(levelSlice.actions.setCurrentTaskGroup(null))
            dispatch(levelSlice.actions.setCurrentTask(null))
        }
    }
    if (!s_list) {
        return null;
    }
    const curValue = state === null ? "-1" : state.communityId.toString()
    return <Grid container direction={"column"} alignItems={"center"}
                 justifyContent={"center"} p={1} gap={0}>
        <Grid>
            <Typography justifyContent={"center"} variant={"body1"}>Select your Community</Typography>
        </Grid>
        <br/>
        <Grid sx={{minWidth: 170}}>
            <Select value={curValue} onChange={ocf} fullWidth={true}>
                <MenuItem value={"-1"} disabled={true}><i>Community</i></MenuItem>
                {s_list.map((v, i) => {
                    return <MenuItem value={v.communityId} key={i}>{v.communityName}</MenuItem>
                })}
            </Select>
        </Grid>
    </Grid>
}
