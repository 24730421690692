import useUserCommunities from "../../hooks/useUserCommunityList";
import {Button, Dialog, Grid, Typography} from "@mui/material";
import UserCommunityCard from "./UserCommunityCard";
import React, {useState} from "react";

export default function AddToCommunity() {
    const userCommunities = useUserCommunities()

    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    if (!userCommunities) {
        return <>
        <Button variant={"outlined"} color={"info"} onClick={handleClickOpen} sx={{width: "170px"}} >Invite Levely to your Community</Button>
        <Dialog open={open} onClose={handleClose}>
            <Grid sx={{padding: 2}}>
                <Typography sx={{fontSize: 13}} color="text.secondary" gutterBottom>
                    <i>No Communities available</i>
                </Typography>
            </Grid>
        </Dialog>
        </>
    } else {
        return <Grid>
        <Button variant={"outlined"} color={"info"} onClick={handleClickOpen} sx={{width: "170px"}} >Invite Levely to your Community</Button>
            <Dialog open={open} onClose={handleClose}>
               <Grid sx={{padding: 2}}>
                   <Typography variant={"body1"}>Select Community</Typography>
                   <Grid container direction={"column"} spacing={2} sx={{padding: 2}}>
                       {
                           userCommunities.map(v => {
                               return <Grid item xs={12} md={6} key={v.id}>
                                   <UserCommunityCard community={v}/>
                               </Grid>
                           })
                       }
                   </Grid>
               </Grid>

            </Dialog>
        </Grid>
    }
}