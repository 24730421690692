import React, {ChangeEvent, useState} from "react";
import {useAppSelector} from "../../../redux/hooks";
import RecordList from "./AuditList";
import {
    Accordion,
    Box,
    Card,
    FormControlLabel,
    FormLabel,
    Grid,
    Paper,
    Radio,
    RadioGroup,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Typography
} from "@mui/material";
import FormControl from "@mui/material/FormControl";

const Records = () => {
    const curState = useAppSelector(s => s.dashboard.curServer)
    const [filter, setFilter] = useState("All")

    if (!curState) {
        return <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            <i>Select Community to show details</i>
        </Typography>
    }

    const ocf = (e: ChangeEvent<HTMLInputElement>) => {
        setFilter(e.target.value)
    }

    return <Grid>
        <Paper sx={{width: '100%', overflow: 'hidden'}}>
            <Box>
                <Card sx={{padding: 2, margins: 3}}>
                    <Grid>
                        <FormControl disabled>
                            <FormLabel>Filters</FormLabel>
                            <RadioGroup row name={"filter"} defaultValue={"All"} onChange={ocf}>
                                <FormControlLabel value={"All"} control={<Radio/>} label={"All"}/>
                                <FormControlLabel value={"GamePlay"} control={<Radio/>} label={"Game Play"}/>
                                <FormControlLabel value={"Server"} control={<Radio/>} label={"Server"}/>
                                <FormControlLabel value={"UserSpecific"} control={<Radio/>} label={"User Specific"}/>
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Card>
            </Box>
            <TableContainer component={Paper}>

                <Table stickyHeader={true}>
                    <TableHead>
                        <Accordion>
                            <TableRow sx={{width: '100%'}}>
                                <TableCell sx={{width: 25}}><b>Audit ID</b></TableCell>
                                <TableCell sx={{width: 120}}><b>Audit Date</b></TableCell>
                                <TableCell sx={{width: 25}}><b>User ID</b></TableCell>
                                <TableCell sx={{width: 40}}><b>Audit Code</b></TableCell>
                                <TableCell sx={{width: 850}}><b>Details</b><br/><i>Click to expand</i></TableCell>
                            </TableRow>
                        </Accordion>
                    </TableHead>
                    <RecordList filterSelected={filter}/>
                </Table>
            </TableContainer>
        </Paper>
    </Grid>
}

export default Records;