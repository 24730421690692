import {
    Box,
    Button,
    Card,
    Checkbox,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    TextField,
    Typography
} from "@mui/material";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import React, {ChangeEvent, ChangeEventHandler, useState} from "react";
import useSaveTask, {LevelData, SaveTaskStatus, VerificationType} from "../../../hooks/useSaveTask";
import {levelSlice} from "../../../redux/store";
import useDeleteTask, {DeleteTaskStatus} from "../../../hooks/useDeleteTask";
import {useNavigate} from "react-router-dom";

function getVerificationType(v: string) {
    if (v === "M") {
        return VerificationType.Moderator
    } else {
        return VerificationType.Trivia
    }
}


function EditAnswer(props: { type: VerificationType, value: string, onChange: ChangeEventHandler<HTMLInputElement> }) {
    if (props.type != VerificationType.Trivia){
        return null
    }
    return <TextField value={props.value} onChange={props.onChange} label={"answer"} name={"answer"}/>
}

function CreateEditTask() {
    const curTask = useAppSelector(s => s.levels.currentTask)

    const initState: LevelData = {
        description: curTask === null ? "" : curTask.taskDescription,
        exposure: curTask === null ? false : curTask.taskExposureFlag,
        answer: curTask === null ? "" : curTask.taskAnswer,
        verificationType: curTask === null ? VerificationType.Moderator : getVerificationType(curTask.taskType)
    }
    const dispatch = useAppDispatch()
    const [levelData, setLevelData] = useState<LevelData>(initState)
    const saveTask = useSaveTask()
    const deleteTask = useDeleteTask()
    const navigate = useNavigate()
    const [emesg, setEmesg] = useState("")

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(levelSlice.actions.setSaveTask(true))
        if (e.target.name === "description") {
            setLevelData(s => ({...s, description: e.target.value}))
        } else if (e.target.name === "exposure") {
            setLevelData(s => ({...s, exposure: e.target.checked}))
        } else if (e.target.name === "verifyType") {
            setLevelData(s => ({...s, verificationType: e.target.value as unknown as VerificationType}))
        } else if (e.target.name === "answer") {
            setLevelData(s => ({...s, answer: e.target.value}))
        }
    }

    const onClick = () => {
        saveTask(levelData).then(r => {
            switch (r) {
                case SaveTaskStatus.ALREADY_LOGGED_OUT: {
                    navigate("/")
                    break
                }
                case SaveTaskStatus.NO_RIGHT: {
                    setEmesg("You do not have privileges to edit.")
                    break
                }
                case SaveTaskStatus.UNKNOWN: {
                    setEmesg("Unknown error")
                    break
                }
                case SaveTaskStatus.NETWORK: {
                    setEmesg("Network error")
                    break
                }
                case SaveTaskStatus.INVALID_GROUP: {
                    setEmesg("Task Group is invalid.")
                    break
                }
                case SaveTaskStatus.INVALID_LEVEL: {
                    setEmesg("Level is invalid.")
                    break
                }
                case SaveTaskStatus.INVALID_DESC: {
                    setEmesg("Task Description is a required field.")
                    break
                }
                case SaveTaskStatus.INVALID_ASSOC: {
                    setEmesg("No association possible between with given Community, Task and Level or Group.")
                    break
                }
                case SaveTaskStatus.INVALID_TASK: {
                    setEmesg("Update is invalid, no matching record found.")
                    break
                }
                case SaveTaskStatus.DATABASE: {
                    setEmesg("Internal error")
                    break
                }
                case SaveTaskStatus.OK: {
                    setEmesg("Successfully updated.")
                    // dispatch(levelSlice.actions.setOpenEditTaskDrawer(false))
                    dispatch(levelSlice.actions.setSaveTask(false))
                    dispatch(levelSlice.actions.setSaveTaskStatus(false))
                    break
                }
                case SaveTaskStatus.NO_CHANGE: {
                    setEmesg("No changes were made.")
                    dispatch(levelSlice.actions.setSaveTask(false))
                    dispatch(levelSlice.actions.setSaveTaskStatus(false))
                    break
                }
            }
        })
    }

    const onDelete = () => {
            deleteTask().then(r => {
                switch (r) {
                    case DeleteTaskStatus.NO_RIGHT: {
                        setEmesg("You do not have privileges to edit.")
                        break
                    }
                    case DeleteTaskStatus.UNKNOWN: {
                        setEmesg("Unknown error")
                        break
                    }
                    case DeleteTaskStatus.OK: {
                        dispatch(levelSlice.actions.setOpenEditTaskDrawer(false))
                        dispatch(levelSlice.actions.setCurrentTask(null))
                        dispatch(levelSlice.actions.setSaveTask(false))
                        dispatch(levelSlice.actions.setSaveTaskStatus(false))
                        break
                    }
                }
            })
    }

    return <Card>
        <Box p={1} m={1}>
            <Grid container direction={"column"} alignItems={"start"} gap={2}>
                <Grid alignSelf={"start"} pb={2}>
                    <Typography variant={"h6"}>{curTask === null ? "Create" : "Edit"} Task</Typography>
                </Grid>
                <TextField multiline value={levelData.description} label={"Task Description"} name={"description"}
                           onChange={onChange}/>
                <FormControlLabel control={<Checkbox name={"exposure"} value={levelData.exposure} onChange={onChange}/>}
                                  label={<Typography maxWidth={"15rem"}>This task potentially exposes the player's
                                      identity in real life</Typography>}/>
                <RadioGroup value={levelData.verificationType} name={"verifyType"} onChange={onChange}>
                    <FormControlLabel control={<Radio/>} label={"Verify by moderator"}
                                      value={VerificationType.Moderator}/>
                    <FormControlLabel control={<Radio/>} label={"Player answers a question"}
                                      value={VerificationType.Trivia}/>
                </RadioGroup>
                <EditAnswer type={levelData.verificationType} value={levelData.answer} onChange={onChange}/>
                <Grid container direction={"row"} gap={8} justifyContent={"space-between"}>
                    <Grid>
                        <Button onClick={onDelete} color={"warning"}>Delete</Button>
                    </Grid>
                    <Grid>
                        <Button variant={"contained"} onClick={onClick}>Save</Button>
                    </Grid>
                </Grid>
                <Grid item hidden={!emesg}>
                    <Typography variant={"subtitle2"} color={"error"}>{emesg}</Typography>
                </Grid>
            </Grid>
        </Box>
    </Card>
}

export default function _CreateEditTask() {
    const open = useAppSelector(s => s.levels.openEditTaskDrawer)
    if (!open) {
        return null
    }
    return <CreateEditTask/>
}