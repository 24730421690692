import {gql, useQuery} from "@apollo/client";
import {useAppSelector} from "../redux/hooks";

const GET_LEADERBOARD_GQL = gql`query getLeaderBoard($ath: String!, $communityId: Int!){
    getLeaderBoard(authToken: $ath, communityId: $communityId){
        userId
        userNickname
        experiencePoints
        }
    }`

type ReqVars = { ath: string, communityId: number }

export interface LeaderBoard {
    userId: number,
    userNickname: string,
    experiencePoints:number

}

export interface LeaderBoardReturn {
    getLeaderBoard: LeaderBoard[]
}

export default function useGetLeaderBoard(cid: number) {
    const authToken = useAppSelector(s => s.auth.authKey)
    const {
        loading,
        error,
        data
    } = useQuery<LeaderBoardReturn, ReqVars>(GET_LEADERBOARD_GQL, {variables: {ath: authToken, communityId: cid}})
    if (!cid) {
        return false
    }
    if (loading && !error) {
        return false
    }
    if (error) {
        return []
    }
    if (data) {
        return data.getLeaderBoard
    }
    return []
}