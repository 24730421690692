import {Accordion, AccordionDetails, AccordionSummary, TableCell, TableRow, Typography} from "@mui/material";
import React from "react";
import useGetAuditRecords from "../../../hooks/useGetAuditRecords";
import {useAppSelector} from "../../../redux/hooks";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function AuditList({filterSelected}: { filterSelected: string }) {
    const curState = useAppSelector(s => s.dashboard.curServer)
    const r_list = useGetAuditRecords(curState?.communityId as number)

    let codes: string[] = []
    if (filterSelected === "GamePlay") {
        codes = ["TASK", "LEVL"]
    } else if (filterSelected === "Server") {
        codes = ["CUER", "UAER"]
    } else if(filterSelected === "UserSpecific") {
        codes = ["DONE"]
    } else {
        codes = ["TASK", "LEVL", "DONE", "ATLU",
            "AXUN",
            "CCER",
            "CCRI",
            "CDER",
            "CINF",
            "CIRI",
            "CLNF",
            "CPCE",
            "CPER",
            "CUCE",
            "CUER",
            "CUGU",
            "CUNF",
            "GCEE",
            "GCER",
            "GCNF",
            "GCRE",
            "GCUE",
            "GDCN",
            "GDDL",
            "GDJE",
            "GDJN",
            "GDLE",
            "GEEE",
            "GEER",
            "GJNE",
            "GNCF",
            "GNFE",
            "GUCE",
            "GUER",
            "ICUE",
            "IERR",
            "IICN",
            "INER",
            "LCER",
            "LDRN",
            "LDUF",
            "LVRF",
            "MCCE",
            "MCUE",
            "MEER",
            "MNFE",
            "MTAE",
            "OCEE",
            "OEEE",
            "OIRE",
            "OOCR",
            "OOEE",
            "PLAY",
            "RGCE",
            "SIER",
            "SPUN",
            "TCTE",
            "TCXE",
            "TINF",
            "TKLN",
            "TKNF",
            "TVLN",
            "UAER",
            "UCUE",
            "UNNF",]
    }


    if (!r_list || r_list.length === 0) {
        return <>
            <Typography sx={{fontSize: 13}} color="text.secondary" gutterBottom>
                <i>No Records available</i>
            </Typography>
        </>
    } else {
        return <>

            {r_list.map((s, k) => {
                if (codes.includes(s.auditCode)) {
                    return <>
                        <Accordion variant={"outlined"} key={k}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                <TableRow sx={{"& td": {border: 0}}}>
                                    <TableCell sx={{width: 25}}>
                                        {s.auditID}
                                    </TableCell>
                                    <TableCell sx={{width: 120}}>
                                        {s.auditDate}
                                    </TableCell>
                                    <TableCell sx={{width: 25}}>
                                        {s.userId}
                                    </TableCell>
                                    <TableCell sx={{width: 40}}>
                                        {s.auditCode}
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant={"body2"} sx={{
                                            maxWidth: '700px',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis'
                                        }}>
                                            {s.details}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </AccordionSummary>
                            <AccordionDetails>
                                <pre>
            <Typography sx={{fontSize: 14}}>
    {JSON.stringify(JSON.parse(s.details), null, 2)}
</Typography>
                                </pre>
                            </AccordionDetails>
                        </Accordion>

                    </>
                } else {
                    return null
                }
            })
            }
        </>
    }
}