import {gql, useQuery} from "@apollo/client";
import {useAppSelector} from "../redux/hooks";

const GET_GAMES_GQL = gql`query getDefinedGames($ath: String!){
    getDefinedGames(authToken: $ath){
        gameId
        gameName
        gameDescription
        }
    }`

type ReqVars = { ath: string }

export interface Game {
    gameId: number,
    gameName: string,
    gameDescription: string
}

interface GameReturn {
    getDefinedGames: Array<Game>
}

export default function useDefinedGames() : Array<Game>{
    const authToken = useAppSelector(s => s.auth.authKey)
    const {
        loading, error, data
    } = useQuery<GameReturn, ReqVars>(GET_GAMES_GQL, {variables: {ath: authToken}})
    if (loading) {
        return []
    }
    if (error) {
        // console.log(error) // causing issues by flooding the damn console
        return []
    } else if (data) {
        return data.getDefinedGames
    } else {
        return []
    }
}
