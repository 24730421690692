import React from "react";
import useGetOrValidateAuthKey from "./hooks/useGetAuthKey";
import {Typography} from "@mui/material";



export default function App() {
    const authkey = useGetOrValidateAuthKey()
    // const isLoggedIn = useAppSelector(state => state.auth.isLoggedIn)
    if (authkey === null) {
        return <>
            <Typography variant={"h2"}>Setting things up!</Typography>
        </>
    }
    return null
}