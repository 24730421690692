import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import useSaveLevel, {LevelIn, SaveLevelStatus} from "../../../hooks/useSaveLevel";
import React, {useState} from "react";
import {levelSlice} from "../../../redux/store";
import {Box, Button, Card, Grid, TextField, Typography} from "@mui/material";
import useDeleteLevel, {DeleteLevelStatus} from "../../../hooks/useDeleteLevel";
import {useNavigate} from "react-router-dom";
import {isWebUri} from "valid-url";

function CreateEditLevel() {
    const curLevel = useAppSelector(s => s.levels.currentLevel)

    const curCommunity = useAppSelector(s => s.dashboard.curServer?.communityId as number)
    const initState: LevelIn = {
        levelName: curLevel === null ? "" : curLevel.levelName,
        levelAdjective: curLevel === null ? "" : curLevel.levelAdjective,
        xpThreshold: curLevel === null ? 0 : curLevel.xpThreshold,
        levelId: curLevel === null ? 0 : curLevel.levelId,
        communityId: curLevel === null ? 0 : curCommunity,
        achievementURL: curLevel === null ? "" : curLevel.achievementURL,
        achievementTags: curLevel === null ? "" : curLevel.achievementTags,
    }
    const dispatch = useAppDispatch()
    const [levels, setLevels] = useState<LevelIn>(initState)
    const saveLevel = useSaveLevel()
    const deleteLevel = useDeleteLevel()
    const navigate = useNavigate()
    const [emesg, setEmesg] = useState("")

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(levelSlice.actions.setSaveLevel(true))
        setLevels(s => ({...s, [e.target.name]: e.target.value}))
    }

    const onClick = async (e: React.FormEvent) => {
        e.preventDefault()
        if (levels.achievementURL != null) {
            if (!isWebUri(levels.achievementURL)){
                setEmesg(("Achievement URL is not valid."))
                return
            }
        }
        saveLevel(levels).then(r => {
            switch (r) {
                case SaveLevelStatus.ALREADY_LOGGED_OUT: {
                    navigate("/")
                    break
                }
                case SaveLevelStatus.NO_RIGHT: {
                    setEmesg("You do not have privileges to edit.")
                    break
                }
                case SaveLevelStatus.UNKNOWN: {
                    setEmesg("Unknown error")
                    break
                }
                case SaveLevelStatus.NETWORK: {
                    setEmesg("Network error")
                    break
                }
                case SaveLevelStatus.INVALID_LEVEL: {
                    setEmesg("Update is invalid, no matching record found.")
                    break
                }
                case SaveLevelStatus.INVALID_THRESHOLD: {
                    setEmesg("XP Threshold is a required field.")
                    break
                }
                case SaveLevelStatus.DATABASE: {
                    setEmesg("Internal error")
                    break
                }
                case SaveLevelStatus.OK: {
                        setEmesg("Successfully updated.")
                        // dispatch(levelSlice.actions.setOpenEditLevelDrawer(false))
                        dispatch(levelSlice.actions.setSaveLevel(false))
                        dispatch(levelSlice.actions.setSaveLevelStatus(false))
                    break
                }
                case SaveLevelStatus.NO_CHANGE: {
                    setEmesg("No changes were made.")
                    dispatch(levelSlice.actions.setSaveLevel(false))
                    dispatch(levelSlice.actions.setSaveLevelStatus(false))
                    break
                }
            }
        })
        return false
    }

    const onDelete = () => {
            deleteLevel().then(r => {
                switch (r) {
                    case DeleteLevelStatus.NO_RIGHT: {
                        setEmesg("You do not have privileges to edit.")
                        break
                    }
                    case DeleteLevelStatus.TASKS_REMAINING: {
                        setEmesg("Delete or Reassign all tasks from the level before deleting the level.")
                        break
                    }
                    case DeleteLevelStatus.UNKNOWN: {
                        setEmesg("Unknown error")
                        break
                    }
                    case DeleteLevelStatus.OK: {
                        dispatch(levelSlice.actions.setOpenEditLevelDrawer(false))
                        dispatch(levelSlice.actions.setCurrentLevel(null))
                        dispatch(levelSlice.actions.setSaveLevel(false))
                        dispatch(levelSlice.actions.setSaveLevelStatus(false))
                        break
                    }
                }
            })
    }

    return <Card>
        <Box p={1} m={1}>
            <form onSubmit={onClick}>
                <Grid container direction={"column"} alignItems={"start"} gap={2}>
                    <Grid alignSelf={"start"} pb={2}>
                        <Typography variant={"h6"}>{curLevel === null ? "Create" : "Edit"} Level</Typography>
                    </Grid>
                    <TextField required value={levels.levelName} label={"Level Name"} name={"levelName"}
                               onChange={onChange}/>
                    <TextField value={levels.levelAdjective} label={"Level Adjective"} name={"levelAdjective"}
                               onChange={onChange}/>
                    <TextField required value={levels.xpThreshold} label={"XP Threshold"} name={"xpThreshold"}
                               onChange={onChange}/>
                    <TextField multiline value={levels.achievementURL} type={"url"} label={"Achievement URL"} id={"url"}
                               name={"achievementURL"}
                               onChange={onChange}/>
                    <TextField multiline value={levels.achievementTags} label={"Achievement Roles"}
                               name={"achievementTags"}
                               onChange={onChange}/>
                    <Grid container direction={"row"} gap={8} justifyContent={"space-between"}>
                        <Grid>
                            <Button onClick={onDelete} color={"warning"}>Delete</Button>
                        </Grid>
                        <Grid>
                            <Button variant={"contained"} type={"submit"}>Save</Button>
                        </Grid>
                    </Grid>
                    <Grid item hidden={!emesg}>
                        <Typography variant={"subtitle2"} color={"error"}>{emesg}</Typography>
                    </Grid>
                </Grid>
            </form>
        </Box>
    </Card>
}

export default function _CreateEditLevel() {
    const open = useAppSelector(s => s.levels.openEditLevelDrawer)
    if (!open) {
        return null
    }
    return <CreateEditLevel/>
}