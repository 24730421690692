import {gql, useMutation} from "@apollo/client";
import {useAppSelector} from "../redux/hooks";
import {GET_LEVELS_GQL} from "./useGetLevels";

const SAVE_GROUP_GQL = gql`mutation createUpdateGroup($ath: String!, $group: TaskGroupIn!) {
    createUpdateGroup(authToken: $ath, groupData: $group)
    }`

export interface TaskGroupIn {
    groupId?: number,
    communityId: number,
    groupName: string,
    groupTags?: string,
    activeFlag: number
}

interface SaveGroupInput {
    ath: string,
    group: TaskGroupIn
}

export enum SaveGroupStatus {
    OK,
    NO_CHANGE,
    NO_RIGHT,
    IAUTHTK,
    ALREADY_LOGGED_OUT,
    NETWORK,
    INVALID_GROUP,
    DATABASE,
    UNKNOWN,
}

interface SaveGroupOutput {
    createUpdateTaskGroup: number
}

export default function useSaveTaskGroup() {
    const [saveGroup] = useMutation<SaveGroupOutput, SaveGroupInput>(SAVE_GROUP_GQL, {
        refetchQueries: [GET_LEVELS_GQL]
    })

    const {curGroupId, ath, cid} = useAppSelector(s => ({
        curGroupId: s.levels.currentTaskGroup?.groupId as number,
        ath: s.auth.authKey,
        cid: s.dashboard.curServer?.communityId as number
    }))

    return (s: TaskGroupIn) => {
        const data: SaveGroupInput = {
            group: {
                groupId: curGroupId,
                communityId: cid,
                groupName: s.groupName,
                groupTags: s.groupTags,
                activeFlag: s.activeFlag,

            }, ath: ath
        }
        return saveGroup({variables: data}).then(r => {
            if (r.errors) {
                const e = r.errors[0]
                const emesg = e.toString()
                if (emesg.includes("INVCOM")) {
                    return SaveGroupStatus.NO_RIGHT
                } else if (emesg.includes("IAUTHTK")) {
                    window.location.reload()
                } else if (emesg.includes("NOUSER")) {
                    return SaveGroupStatus.ALREADY_LOGGED_OUT
                } else if (emesg.includes("CUDBFAIL")) {
                    return SaveGroupStatus.NO_CHANGE
                } else if (emesg.includes("INVGROUP")) {
                    return SaveGroupStatus.INVALID_GROUP
                } else if (emesg.includes("DBINT")) {
                    return SaveGroupStatus.DATABASE
                }
                return SaveGroupStatus.UNKNOWN
            }
            const data = r.data
            if (data) {
                if (data.createUpdateTaskGroup !== null) {
                    return SaveGroupStatus.OK
                } else {
                    return SaveGroupStatus.UNKNOWN
                }
            } else {
                return SaveGroupStatus.UNKNOWN
            }
        }).catch(e => {
            const emesg = e.toString()
            if (emesg.includes("Failed to fetch")) {
                return SaveGroupStatus.NETWORK
            } else if (emesg.includes("INVCOM")) {
                return SaveGroupStatus.NO_RIGHT
            } else if (emesg.includes("IAUTHTK")) {
                window.location.reload()
                return SaveGroupStatus.UNKNOWN
            } else if (emesg.includes("NOUSER")) {
                return SaveGroupStatus.ALREADY_LOGGED_OUT
            } else if (emesg.includes("CUDBFAIL")) {
                return SaveGroupStatus.NO_CHANGE
            } else if (emesg.includes("INVGROUP")) {
                return SaveGroupStatus.INVALID_GROUP
            } else if (emesg.includes("DBINT")) {
                return SaveGroupStatus.DATABASE
            } else {
                return SaveGroupStatus.UNKNOWN
            }
        })
    }
}