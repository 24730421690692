import {gql, useQuery} from "@apollo/client";
import {useAppSelector} from "../redux/hooks";

const GET_RECORDS_GQL = gql`query getAuditRecords($ath: String!, $communityId: Int!){
    getAuditRecords(authToken: $ath, communityId: $communityId){
        auditID
        auditDate
        auditCode
        userId
        details
        }
    }`

type ReqVars = { ath: string, communityId: number }

export interface AuditRecord {
    auditCode: string,
    auditID: number,
    auditDate: string,
    userId: number,
    details: string
}

export interface AuditRecordReturn {
    getAuditRecords: AuditRecord[]
}

export default function useGetAuditRecords(cid: number) {
    const authToken = useAppSelector(s => s.auth.authKey)
    const {
        loading,
        error,
        data
    } = useQuery<AuditRecordReturn, ReqVars>(GET_RECORDS_GQL, {variables: {ath: authToken, communityId: cid}})
    if (!cid) {
        return false
    }
    if (loading && !error) {
        return false
    }
    if (error) {
        return []
    }
    if (data) {
        return data.getAuditRecords
    }
    return []
}