import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {Select, SelectChangeEvent, Typography} from "@mui/material";
import {CommunityLevel} from "../../../hooks/useGetLevels";
import {levelSlice} from "../../../redux/store";
import MenuItem from "@mui/material/MenuItem";
import React from "react";

export default function SelectLevel(props: { levels: CommunityLevel[] }) {
    const curLevel = useAppSelector(s => s.levels.currentLevel)
    const dispatch = useAppDispatch()

    const saveTaskState = useAppSelector(s => s.levels.unsavedTask)
    const saveLevelState = useAppSelector(s => s.levels.unsavedLevel)
    const saveTaskGroupState = useAppSelector(s => s.levels.unsavedTaskGroup)

    const val = curLevel === null ? "-1" : curLevel.levelId
    const setVal = (e: SelectChangeEvent) => {
        const toSet = props.levels.find(s => s.levelId === parseInt(e.target.value))
        if (toSet === undefined) {
            return;
        }
        if(saveTaskState) {
            dispatch(levelSlice.actions.setSaveTaskStatus(true))
        }
        else if(saveTaskGroupState) {
            dispatch(levelSlice.actions.setSaveTaskGroupStatus(true))
        }
        else if(saveLevelState) {
            dispatch(levelSlice.actions.setSaveLevelStatus(true))
        }
        else {
            dispatch(levelSlice.actions.setCurrentLevel(toSet))
        }

    }
    if (!props.levels) {
        return <>
            <Typography sx={{fontSize: 13}} color="text.secondary" gutterBottom>
                <i>No Levels available</i>
                <br/>
                <i>Start by creating one</i>
            </Typography>
        </>
    }
    return <Select value={val.toString()} onChange={setVal}>
        <MenuItem disabled value={val}><i>Select a Level</i></MenuItem>
        {
            props.levels.map((v, k) => {
                return <MenuItem key={k} value={v.levelId}>
                    {v.levelName}
                </MenuItem>
            })
        }
    </Select>

}