import {useAppSelector} from "../redux/hooks";
import {gql, useQuery} from "@apollo/client";
import {useEffect} from "react";

const GET_COMMUNITES_GQL = gql`query getUserCommunities($ath: String!){
  getUserCommunities(authToken: $ath){
    name,
    id,
    communityType
  }
}`

export interface UserCommunity {
    name: string,
    id: string,
    communityType: number
}

type ReqVars = { ath: string }

interface UserCommunityReturn {
    getUserCommunities : Array<UserCommunity>
}

export default function useUserCommunities() : Array<UserCommunity> {
    const authToken = useAppSelector(state => state.auth.authKey)
    const {
        loading,
        error,
        data,
        startPolling,
        stopPolling
    } = useQuery<UserCommunityReturn, ReqVars>(GET_COMMUNITES_GQL, {variables: {ath: authToken}, pollInterval: 120000})

    useEffect(() => {
        startPolling(120000)
        return () => {
            stopPolling()
        }
    })
    if (loading) {
        return []
    }
    if (error) {
        console.log(error)
        return []
    } else if (data) {
        return data.getUserCommunities
    } else {
        return []
    }
}