import UnsavedDialog from "../UnsavedDialog";
import {useAppSelector} from "../../../redux/hooks";

export default function AllUnsavedDialogs() {
    return <>
    <UnsavedDialog type={"Task"} status={useAppSelector(s => s.levels.unsavedTaskStatus)}/>
    <UnsavedDialog type={"Level"} status={useAppSelector(s => s.levels.unsavedLevelStatus)}/>
    <UnsavedDialog type={"Task Group"} status={useAppSelector(s => s.levels.unsavedTaskGroupStatus)}/>
    <UnsavedDialog type={"Community"} status={useAppSelector(s => s.community.unsavedCommunityStatus)}/>
    </>
}