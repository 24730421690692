    import {gql, useMutation} from "@apollo/client";
import {useAppSelector} from "../redux/hooks";

const LOGIN_MUTATION = gql`mutation login($auth: String!, $passwd: String!, $uname: String!){
  login(authToken: $auth, username: $uname, passwd: $passwd){
    userId,
  }
}`

interface LoginVariables {
    uname: string,
    passwd: string,
    auth: string
}

interface LoginReturn {
    login: {
        userId: string,
    }
}

export enum LoginStatus {
    OK,
    INVALID_PASS,
    INVALID_USERNAME,
    UNKNOWN_USERNAME,
    UNKNOWN,
    NETWORK
}

export default function useLogin(): (username: string, password: string) => Promise<LoginStatus> {
    const [login] = useMutation<LoginReturn, LoginVariables>(LOGIN_MUTATION)
    const authKey = useAppSelector(s => s.auth.authKey)
    return (username: string, password: string) => {
        if ((!username || !password)) {
            return new Promise(() => LoginStatus.INVALID_PASS);
        }
        return login({variables: {auth: authKey, passwd: password, uname: username}}).then(r => {
            if (r.errors) {
                // throw r.errors[0]
                const emesg = JSON.stringify(r.errors)
                if (emesg.includes("Failed to fetch")) {
                    return LoginStatus.NETWORK
                } else if (emesg.includes("LGUS") || emesg.includes("LGPASS" || emesg.includes("LGPW"))) {
                    return LoginStatus.INVALID_PASS
                } else if (emesg.includes("IAUTHTK")) {
                    window.location.reload()
                } else {
                    console.log(emesg)
                    return LoginStatus.UNKNOWN
                }
            }
            const data = r.data
            if (data) {
                if (data.login !== null) {
                    return LoginStatus.OK
                } else {
                    return LoginStatus.UNKNOWN
                }
            } else {
                return LoginStatus.UNKNOWN
            }
        }).catch(e => {
            const emesg = JSON.stringify(e)
            if (emesg.includes("Failed to fetch")) {
                return LoginStatus.NETWORK
            } else if (emesg.includes("LGPW" || emesg.includes("LGPASS"))) {
                return LoginStatus.INVALID_PASS
            } else if (emesg.includes("CAULEN")) {
                return LoginStatus.INVALID_USERNAME
            } else if (emesg.includes("LGUS")) {
                return LoginStatus.UNKNOWN_USERNAME
            }
            else if (emesg.includes("IAUTHTK")) {
                window.location.reload()
            } else {
                console.log(emesg)
                return LoginStatus.UNKNOWN
            }
            return LoginStatus.UNKNOWN
        });
    }
}