import {Accordion, AccordionDetails, AccordionSummary, Typography} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";

export default function Dropdown({name, content}: {name: string, content: string}) {
    return <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
            <Typography>{name}</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Typography textAlign={"justify"} variant={"body2"}>
                {content}
            </Typography>
        </AccordionDetails>
    </Accordion>
}