import {gql, useQuery} from "@apollo/client";
import {useAppSelector} from "../redux/hooks";

const GET_TASK_HISTORY_GQL = gql`query getUserTaskHistory($ath: String!, $communityId: Int!, $userId: Int!){
    getUserTaskHistory(authToken: $ath, communityId: $communityId, userId: $userId){
        historyId
        TaskId
        UserId
        CommunityId
        dateIssued
        dateAchieved
        rejectedCount
        }
    }`

type ReqVars = { ath: string, communityId: number, userId: number }

export interface TaskHistory {
    historyId: number,
    TaskId: number,
    UserId: number,
    CommunityId: number,
    dateIssued: string,
    dateAchieved:string,
    rejectedCount: number,
}

export interface TaskHistoryReturn {
    getUserTaskHistory: TaskHistory[]
}

export default function useGetUserTaskHistory(uid: number) {
    const authToken = useAppSelector(s => s.auth.authKey)
    const cid = useAppSelector(s => s.dashboard.curServer?.communityId)

    const {
        loading,
        error,
        data
    } = useQuery<TaskHistoryReturn, ReqVars>(GET_TASK_HISTORY_GQL, {variables: {ath: authToken, userId: uid, communityId: cid as number}})
    if(!cid || !uid) {
        return false
    }
    if (loading && !error) {
        return false
    }
    if (error) {
        return []
    }
    if (data) {
        return data.getUserTaskHistory
    }
    return []
}
