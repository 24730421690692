import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import React, {useState} from "react";
import {levelSlice} from "../../../redux/store";
import {Box, Button, Card, Checkbox, FormControlLabel, Grid, TextField, Typography} from "@mui/material";
import useSaveTaskGroup, {SaveGroupStatus, TaskGroupIn} from "../../../hooks/useSaveTaskGroup";
import useDeleteGroup, {DeleteGroupStatus} from "../../../hooks/useDeleteTaskGroup";
import {useNavigate} from "react-router-dom";
import MultipleSelectChip from "../community/RoleSelector";

function CreateEditGroup() {
    const curGroup = useAppSelector(s => s.levels.currentTaskGroup)

    const curCommunity = useAppSelector(s => s.dashboard.curServer?.communityId as number)
    const initState: TaskGroupIn = {
        groupName: curGroup === null ? "" : curGroup.groupName,
        groupId: curGroup === null ? 0 : curGroup.groupId,
        communityId: curGroup === null ? 0 : curCommunity,
        groupTags: curGroup === null ? "" : curGroup.groupTags,
        activeFlag: curGroup === null ? 0 : curGroup.activeFlag
    }
    const dispatch = useAppDispatch()
    const [groups, setGroups] = useState<TaskGroupIn>(initState)
    const saveGroup = useSaveTaskGroup()
    const deleteGroup = useDeleteGroup()
    const navigate = useNavigate()
    const [emesg, setEmesg] = useState("")

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(levelSlice.actions.setSaveTaskGroup(true))
        setGroups(s => ({...s, [e.target.name]: e.target.value}))
    }
    const onCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(levelSlice.actions.setSaveTaskGroup(true))
        if (e.target.checked) {
            setGroups(s => ({...s, activeFlag: 1}))
        } else {
            setGroups(s => ({...s, activeFlag: 0}))
        }
    }

    const onClick = () => {
        saveGroup(groups).then(r => {
            switch (r) {
                case SaveGroupStatus.ALREADY_LOGGED_OUT: {
                    navigate("/")
                    break
                }
                case SaveGroupStatus.NO_RIGHT: {
                    setEmesg("You do not have privileges to edit.")
                    break
                }
                case SaveGroupStatus.UNKNOWN: {
                    setEmesg("Unknown error")
                    break
                }
                case SaveGroupStatus.NETWORK: {
                    setEmesg("Network error")
                    break
                }
                case SaveGroupStatus.INVALID_GROUP: {
                    setEmesg("Update is invalid, no matching record found.")
                    break
                }
                case SaveGroupStatus.DATABASE: {
                    setEmesg("Internal error")
                    break
                }
                case SaveGroupStatus.OK: {
                        setEmesg("Successfully updated.")
                        // dispatch(levelSlice.actions.setOpenEditTaskGroupDrawer(false))
                        dispatch(levelSlice.actions.setSaveTaskGroup(false))
                        dispatch(levelSlice.actions.setSaveTaskGroupStatus(false))
                    break
                }
                case SaveGroupStatus.NO_CHANGE: {
                    setEmesg("No changes were made.")
                    dispatch(levelSlice.actions.setSaveTaskGroup(false))
                    dispatch(levelSlice.actions.setSaveTaskGroupStatus(false))
                    break
                }
            }
        })
    }

    const onDelete = () => {
            deleteGroup().then(r => {
                switch (r) {
                    case DeleteGroupStatus.NO_RIGHT: {
                        setEmesg("You do not have privileges to edit.")
                        break
                    }
                    case DeleteGroupStatus.TASKS_REMAINING: {
                        setEmesg("Delete or Reassign all tasks from the level before deleting the level.")
                        break
                    }
                    case DeleteGroupStatus.UNKNOWN: {
                        setEmesg("Unknown error")
                        break
                    }
                    case DeleteGroupStatus.OK: {
                        dispatch(levelSlice.actions.setOpenEditTaskGroupDrawer(false))
                        dispatch(levelSlice.actions.setCurrentTaskGroup(null))
                        dispatch(levelSlice.actions.setSaveTaskGroup(false))
                        dispatch(levelSlice.actions.setSaveTaskGroupStatus(false))
                        break
                    }
                }
            })
    }

    return <Card>
        <Box p={1} m={1}>
            <Grid container direction={"column"} alignItems={"start"} gap={2}>
                <Grid alignSelf={"start"} pb={2}>
                    <Typography variant={"h6"}>{curGroup === null ? "Create" : "Edit"} Group</Typography>
                </Grid>
                <TextField required value={groups.groupName} label={"Group Name"} name={"groupName"}
                           onChange={onChange}/>
                {/*<TextField required multiline value={groups.groupTags} label={"Group Roles"}*/}
                {/*           name={"groupTags"}*/}
                {/*           onChange={onChange}/>*/}
                <MultipleSelectChip label={"Group Roles"} selectedRoles={groups.groupTags as string}
                                    name={"groupTags"} setState={setGroups}/>
                <FormControlLabel control={<Checkbox onChange={onCheck} checked={groups.activeFlag !== 0}/>}
                                  value={groups.activeFlag} label={"Active Flag"}/>
                <Grid container direction={"row"} gap={8} justifyContent={"space-between"}>
                    <Grid>
                        <Button onClick={onDelete} color={"warning"}>Delete</Button>
                    </Grid>
                    <Grid>
                        <Button variant={"contained"} onClick={onClick}>Save</Button>
                    </Grid>
                </Grid>
                <Grid item hidden={!emesg}>
                    <Typography variant={"subtitle2"} color={"error"}>{emesg}</Typography>
                </Grid>
            </Grid>
        </Box>
    </Card>
}

export default function _CreateEditGroup() {
    const open = useAppSelector(s => s.levels.openEditTaskGroupDrawer)
    if (!open) {
        return null
    }
    return <CreateEditGroup/>
}