import {gql, useQuery} from "@apollo/client";
import {useAppSelector} from "../redux/hooks";
import {CommunityLevel, CommunityTask} from "./useGetLevels";
//TODO : Check syntax
const GET_USER_GQL = gql`query getUserByNickname($ath: String!, $communityId: Int!, $searchString: String!){
    getUserByNickname(authToken: $ath, communityId: $communityId, searchString: $searchString){
    username
    user {
            userId
            communityId
            createDate
            startDate
            endDate
            profileSyncDate
            endJailDate
            userStatus
            userNickname
            percentGainRate
            experiencePoints
            userPermission
            exposureAllowed
            tagsEarned
            levelAchieved {
              levelId
              communityId
              levelName
              levelAdjective
              xpThreshold
              achievementURL
              achievementTags
              }
            levelChallenged {
              levelId
              communityId
              levelName
              levelAdjective
              xpThreshold
              achievementURL
              achievementTags
              }
            outstandingTask {
              taskId
              communityId
              taskGroupId
              taskLevelId
              taskDescription
              taskImageURL
              taskDifficulty
              taskExposureFlag
              taskApprovalSteps
              taskType
              taskAnswer
              taskParameters
              }
          }
        }
    }`

type ReqVars = { ath: string, communityId: number, searchString: string }

//getUserById and getUserByNickname have different output formats but both are exported as "User"
export interface User {
    username: string,
    user: UserDetails
}

interface UserDetails {
    userId: number,
    communityId: number,
    createDate: string,
    startDate: string,
    endDate: string,
    profileSyncDate: string,
    endJailDate: string,
    userStatus: number,
    userNickname: string,
    percentGainRate: number,
    experiencePoints: number,
    userPermission: number,
    exposureAllowed: number,
    tagsEarned: string,
    levelAchieved: CommunityLevel,
    levelChallenged: CommunityLevel,
    outstandingTask: CommunityTask
}


export interface UserReturnNickname {
    getUserByNickname: Array<User>
}

export default function useGetUserByNickname(nickname: string ): Array<User> {
    const authToken = useAppSelector(s => s.auth.authKey)
    const cid = useAppSelector(s => s.dashboard.curServer?.communityId)
    const {
        loading,
        error,
        data
    } = useQuery<UserReturnNickname, ReqVars>(GET_USER_GQL, {
        variables: {
            ath: authToken,
            searchString: nickname,
            communityId: cid as number
        }
    })
    if (loading) {
        return []
    }
    if (error) {
        console.log(error)
        return []
    } else if (data) {
        return data.getUserByNickname
    } else {
        return []
    }
}

