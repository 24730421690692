import {Box, Divider, Drawer, Grid, Tab, Tabs, Toolbar} from "@mui/material";
import React from "react";
import Members from "./members/MembersTAB";
import Levels from "./levels/LevelsTAB";
import Community from "./community/CommunityTAB";
import Records from "./server/ServerTAB";
import ServerMenu from "./CommunitySelector";
import Games from "./games/GamesTAB";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {communitySlice, levelSlice} from "../../redux/store";
import AllUnsavedDialogs from "./levels/AllUnsavedDialogs";


export default function Tabs_Panel() {
    const [value, setValue] = React.useState(0)
    const dispatch = useAppDispatch()

    const saveTaskState = useAppSelector(s => s.levels.unsavedTask)
    const saveLevelState = useAppSelector(s => s.levels.unsavedLevel)
    const saveTaskGroupState = useAppSelector(s => s.levels.unsavedTaskGroup)
    const saveCommunityState = useAppSelector(s => s.community.unsavedCommunity)

    const handleTabs = (e: any, val: React.SetStateAction<number>) => {
        if (saveTaskState) {
            dispatch(levelSlice.actions.setSaveTaskStatus(true))
        } else if (saveTaskGroupState) {
            dispatch(levelSlice.actions.setSaveTaskGroupStatus(true))
        } else if (saveLevelState) {
            dispatch(levelSlice.actions.setSaveLevelStatus(true))
        } else if (saveCommunityState) {
            dispatch(communitySlice.actions.setSaveCommunityStatus(true))
        } else {
            setValue(val)
        }
    }

    return <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'flex'}}}>
        <AllUnsavedDialogs/>
        <Drawer
            variant="permanent"
            sx={{
                width: 250,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: {width: 250, boxSizing: 'border-box'},
            }}>
            <Toolbar sx={{height: "17vh"}}></Toolbar>
            <Grid>
                <ServerMenu/>
            </Grid>
            <br/>
            <Divider/>
            <Tabs value={value} variant={"standard"} onChange={handleTabs} orientation={"vertical"}>
                <Tab label="Members"/>
                <Tab label="Levels"/>
                <Tab label="Community"/>
                <Tab label="Server"/>
                <Tab label="Install Existing Games"/>
            </Tabs>
        </Drawer>
        <Grid item xs={10}>
            <Box component="main" sx={{flexGrow: 1, p: 0}}>
                <TabPanel value={value} index={0}><Members/></TabPanel>
                <TabPanel value={value} index={1}><Levels/></TabPanel>
                <TabPanel value={value} index={2}><Community/></TabPanel>
                <TabPanel value={value} index={3}><Records/></TabPanel>
                <TabPanel value={value} index={4}><Games/></TabPanel>
            </Box>
        </Grid>
    </Box>
}

function TabPanel(props: { children: any; value: any; index: any; }) {
    const {children, value, index} = props;
    return (<div>
        {value === index && (<h1>{children}</h1>)}
    </div>)
}
