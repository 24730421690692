import {gql, useMutation} from "@apollo/client";
import {useAppSelector} from "../redux/hooks";
import {GET_LEVELS_GQL} from "./useGetLevels";

const SAVE_TASK_GQL = gql`mutation updateTask($ath: String!, $task: TaskIn!) {
  createUpdateTask(authToken: $ath, taskData: $task)
}
`

interface TaskIn {
    taskId?: number,
    taskGroupId: number,
    taskLevelId: number,
    taskDescription?: string,
    taskExposureFlag?: number,
    taskType?: string,
    taskAnswer?: string,
    communityId: number
}

interface SaveTaskInput {
    ath: string,
    task: TaskIn
}

interface SaveTaskOutput {
    createUpdateTask: number
}

export enum SaveTaskStatus {
    OK,
    INVALID_TASK,
    NO_RIGHT,
    NO_CHANGE,
    INVALID_GROUP,
    INVALID_LEVEL,
    INVALID_DESC,
    INVALID_ASSOC,
    DATABASE,
    NETWORK,
    ALREADY_LOGGED_OUT,
    UNKNOWN

}

export default function useSaveTask() {
    const [saveTask, _] = useMutation<SaveTaskOutput, SaveTaskInput>(SAVE_TASK_GQL, {
        refetchQueries: [GET_LEVELS_GQL]
    })
    const {curLevelId, curGroupId, ath, curTaskId, cid} = useAppSelector(s => ({
        curGroupId: s.levels.currentTaskGroup?.groupId as number, // as we should never be able to do this without both
        curLevelId: s.levels.currentLevel?.levelId as number,
        ath: s.auth.authKey,
        curTaskId: s.levels.currentTask === null ? undefined : s.levels.currentTask.taskId, // not casting, as null is wanted here (when creating a task)
        cid: s.dashboard.curServer?.communityId as number
    }))
    return (levelData: LevelData) => {
        const data: SaveTaskInput = {
            task: {
                taskGroupId: curGroupId,
                taskLevelId: curLevelId,
                taskId: curTaskId,
                taskAnswer: levelData.verificationType == VerificationType.Trivia ? levelData.answer : undefined,
                taskType: levelData.verificationType == VerificationType.Trivia ? "T" : "M",
                taskDescription: levelData.description,
                taskExposureFlag: levelData.exposure ? 1 : 0,
                communityId: cid
            }, ath: ath
        }
        return saveTask({variables: data}).then(r => {
            if (r.errors) {
                const e = r.errors[0]
                const emesg = e.toString()
                if (emesg.includes("INVCOM")) {
                    return SaveTaskStatus.NO_RIGHT
                } else if (emesg.includes("IAUTHTK")) {
                    window.location.reload()
                } else if (emesg.includes("NOUSER")) {
                    return SaveTaskStatus.ALREADY_LOGGED_OUT
                } else if (emesg.includes("CUDBFAIL")) {
                    return SaveTaskStatus.NO_CHANGE
                } else if (emesg.includes("INVLEVEL")) {
                    return SaveTaskStatus.INVALID_LEVEL
                } else if (emesg.includes("INVGROUP")) {
                    return SaveTaskStatus.INVALID_GROUP
                } else if (emesg.includes("INVDESC")) {
                    return SaveTaskStatus.INVALID_DESC
                } else if (emesg.includes("INVASSOC")) {
                    return SaveTaskStatus.INVALID_ASSOC
                } else if (emesg.includes("INVTASK")) {
                    return SaveTaskStatus.INVALID_TASK
                } else if (emesg.includes("DBINT")) {
                    return SaveTaskStatus.DATABASE
                }
                return SaveTaskStatus.UNKNOWN
            }
            const data = r.data
            if (data) {
                if (data.createUpdateTask !== null) {
                    return SaveTaskStatus.OK
                } else {
                    return SaveTaskStatus.UNKNOWN
                }
            } else {
                return SaveTaskStatus.UNKNOWN
            }
        }).catch(e => {
            const emesg = e.toString()
            if (emesg.includes("Failed to fetch")) {
                return SaveTaskStatus.NETWORK
            } else if (emesg.includes("INVCOM")) {
                return SaveTaskStatus.NO_RIGHT
            } else if (emesg.includes("IAUTHTK")) {
                window.location.reload()
            } else if (emesg.includes("NOUSER")) {
                return SaveTaskStatus.ALREADY_LOGGED_OUT
            } else if (emesg.includes("CUDBFAIL")) {
                return SaveTaskStatus.NO_CHANGE
            } else if (emesg.includes("INVLEVEL")) {
                return SaveTaskStatus.INVALID_LEVEL
            } else if (emesg.includes("INVGROUP")) {
                return SaveTaskStatus.INVALID_GROUP
            } else if (emesg.includes("INVDESC")) {
                return SaveTaskStatus.INVALID_DESC
            } else if (emesg.includes("INVASSOC")) {
                return SaveTaskStatus.INVALID_ASSOC
            } else if (emesg.includes("INVTASK")) {
                return SaveTaskStatus.INVALID_TASK
            } else if (emesg.includes("DBINT")) {
                return SaveTaskStatus.DATABASE
            }
            return SaveTaskStatus.UNKNOWN
        })
    }
}

export interface LevelData {
    exposure: boolean,
    verificationType: VerificationType,
    answer: string,
    description: string,
}

export enum VerificationType {
    Moderator, Trivia
}